<template>
  <div class="contents">
    <div class="headers">
      <el-button type="primary" icon="el-icon-plus" @click="addfixlog"
        >新增日志</el-button
      >
    </div>      
    <el-empty v-if="forms.length == 0" description="暂无日志"></el-empty>
    <el-timeline v-else :reverse="true" :style="{ height: heights + 'px' }">
      <el-timeline-item v-for="(item,index) in forms" :key="index" :timestamp="item.datas" placement="top" color="#0bbd87" >
        <el-card>
          <h4>问题描述：{{item.description}}</h4>
          <h4>解决情况：{{item.solve}}</h4>
          <p>{{item.devname}} 提交于 {{item.creat_time}}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <!-- 小程序新增窗口 -->
    <el-dialog
      title="添加小程序"
      width="25%"
      :visible.sync="dialogFormVisible"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="当前版本号" prop="version">
          <el-input
           
            v-model="form.version"
            placeholder="请输入当前版本号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题描述" prop="description">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入人当前问题描述"
            v-model="form.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="解决情况" prop="solve">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入问题解决情况"
            v-model="form.solve"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="submitForm('ruleForm')"
          >立即添加</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        version: "",
        description: "",
        solve: "",
        developmentName:''
      },
      rules: {
        version: [
          { required: true, message: "请输入当前版本号", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入问题描述", trigger: "blur" },
        ],
        solve: [{ required: true, message: "请输入解决情况", trigger: "blur" }],
      },
      forms:[],
      heights:'',
    };
  },
  mounted() {
    const _this = this
    this.form.developmentName = window.sessionStorage.getItem("names");
     _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    this.getnewdata();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 200;
    },
    getnewdata() {
        this.$http.get('https://wx.xiaoyuc.com/home/admin/getfoxlog').then(({data})=>{
            console.log(data)
            this.forms = data.data
        })
    },
    addfixlog() {
      this.dialogFormVisible = true;
    },
    resetForm(formitem) {
      this.$refs[formitem].resetFields();
      this.dialogFormVisible = false;
      this.getnewdata();
    },
    submitForm(formitem) {
      const _that = this;
      this.$refs[formitem].validate((valid) => {
        if (valid) {
          _that.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/addfoxlog",
              _that.$qs.stringify(_that.form)
            )
            .then(({ data }) => {
              console.log(data);
              if(data.code == 200){
                  _that.resetForm(formitem);
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
}
.headers {
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  line-height: 60px;
}
.headers > button {
  margin-left: 40px;
}
.el-timeline {
  width: 94%;
  overflow: auto;
}
.el-timeline::-webkit-scrollbar{
  display: none !important;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>