<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="header">
      <el-button type="primary" icon="el-icon-plus" @click="addprogram"
        >新增订单</el-button
      >
      <el-button
        type="success"
        icon="el-icon-refresh-right"
        @click="getaccounts"
        >刷新</el-button
      >
    </div>
    <!-- 抖音号导流订单 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      :max-height="heights2"
      :stripe="true"
      v-loading="tableDataLoading"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
        fixed
        align="center"
        prop="id"
        width="80px"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="shopName"
        label="店铺昵称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="localID"
        label="店铺跳转ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="nickName"
        label="抖音昵称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="dyID" label="抖音号">
      </el-table-column>
      <el-table-column align="center" prop="indexurl" label="抖音跳转ID">
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态">
      </el-table-column>
      <el-table-column align="center" prop="types" label="类型">
      </el-table-column>
      <el-table-column align="center" prop="nums" label="推广次数">
      </el-table-column>
      <el-table-column align="center" prop="alertnums" label="已推广次数">
      </el-table-column>
      <el-table-column
        align="center"
        prop="creat_time"
        label="创建时间"
        width="160"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="380px">
        <template slot-scope="scope">
          <el-button
            style="background-color: #f37c90; color: #fff"
            size="mini"
            @click="getOrderdetail(scope.row.id, 1)"
            >关注数据</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="statuse(scope.row.id, scope.row.status)"
            >状态</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="background-color: #4c71f5; color: #fff"
            @click="editors(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="background-color: #4c71f5; color: #fff"
            @click="delet(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑订单窗口 -->
    <el-dialog
      title="新增订单"
      :visible.sync="programOrder"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        v-loading="dialogLoadings"
        :model="addform"
        :rules="addrules"
        ref="ruleForm"
        label-width="130px"
      >
        <el-form-item label="归属类型" prop="type">
          <el-radio-group v-model="addform.type">
            <el-radio :label="0">电商店铺</el-radio>
            <el-radio :label="1">本地团购</el-radio>
            <el-radio :label="2">公益推广</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addform.type == 0" label="抖音小店名称" prop="shopNames">
          <el-input
            v-model="addform.shopNames"
            autocomplete="off"
            placeholder="请输入抖音小店名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="addform.type == 1" label="本地店铺名称" prop="shopNames">
          <el-input
            v-model="addform.shopNames"
            autocomplete="off"
            placeholder="请输入本地店铺名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="addform.type == 2" label="公益项目名称" prop="shopNames">
          <el-input
            v-model="addform.shopNames"
            autocomplete="off"
            placeholder="请输入公益项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="addform.type == 0" label="抖音小路径" prop="localID">
          <el-input
            v-model="addform.localID"
            autocomplete="off"
            placeholder="请输入抖音小店路径"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="addform.type == 1" label="本地店铺跳转ID" prop="localID">
          <el-input
            v-model="addform.localID"
            autocomplete="off"
            placeholder="请输入本地店铺跳转ID"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="addform.type == 2" label="公益项目路径" prop="localID">
          <el-input
            v-model="addform.localID"
            autocomplete="off"
            placeholder="请输入公益项目路径"
          ></el-input>
        </el-form-item>
        <el-form-item label="抖音号昵称" prop="nickName">
          <el-input
            v-model="addform.nickName"
            autocomplete="off"
            placeholder="请输入抖音号昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="抖音号" prop="dyAccount">
          <el-input
            v-model="addform.dyAccount"
            autocomplete="off"
            placeholder="请输入订单的抖音号"
          ></el-input>
        </el-form-item>
        <el-form-item label="抖音跳转ID" prop="href">
          <el-input
            v-model="addform.href"
            autocomplete="off"
            placeholder="请输入跳转时的需要的用户userid"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="推广次数" prop="nums">
          <el-input
            v-model="addform.nums"
            type="number"
            autocomplete="off"
            placeholder="请输入抖音号昵称"
          ></el-input>
        </el-form-item>

        <el-form-item label="指定运营商" prop="conectappid">
          <el-select
            v-model="addform.conectappid"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in carriers"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="submitForm('ruleForm')"
          >{{ addnamese }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 状态调整窗口 -->
    <el-dialog
      title="优先级调整"
      :visible.sync="statusvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="status">
        <el-radio :label="0">进行中</el-radio>
        <el-radio :label="1">暂停</el-radio>
        <el-radio :label="2">已完成</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(3)" type="danger">取消</el-button>
        <el-button @click="sures(3)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 关注记录 -->
    <el-dialog
      title="引流记录"
      :visible.sync="recordvisible"
      width="70%"
      center
      class="recordse"
      :close-on-click-modal="false"
      @close="closetimes"
    >
      <div class="timeset">
        <el-radio-group @change="gonewsit" v-model="restus">
          <el-radio :label="2">全部</el-radio>
          <el-radio :label="1">已完成</el-radio>
          <el-radio :label="0">未完成</el-radio>
        </el-radio-group>
        <el-date-picker
          v-model="timese"
          @change="changetimes"
          value-format="yyyy-MM-dd"
          type="date"
          default-value="2022-03-20"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <el-table
        :data="recordTable"
        v-if="heights3"
        empty-text="暂无内容"
        :stripe="true"
        style="width: 100%"
        :max-height="heights3"
        :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
        v-loading="recordLoading"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          align="center"
          prop="id"
          width="80px"
          label="id"
        >
        </el-table-column>
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="45"
              :src="scope.row.autUrl"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :show-overflow-tooltip="true"
          prop="nickName"
          label="抖音昵称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="180px"
          prop="openid"
          :show-overflow-tooltip="true"
          label="openid"
        >
        </el-table-column>
        <el-table-column align="center" prop="orderId" label="关注订单">
        </el-table-column>
        <el-table-column
          align="center"
          prop="accountOpenid"
          :show-overflow-tooltip="true"
          label="被关注openid"
        >
        </el-table-column>
        <el-table-column align="center" prop="operatorsId" label="运营商">
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          prop="status"
          label="状态"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="160px"
          prop="update_time"
          label="完成时间"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="size"
        background
        layout="prev, pager, next"
        :total="totals"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      priorvisible: false,
      priors: 3,
      ids: "",
      status: "",
      statusvisible: false,
      accountappids: "",
      accountappidsvisible: false,
      programOrder: false,
      dialogLoading: false,
      recordvisible: false,
      appidlist: [],
      addform: {
        shopNames: "",
        localID: "",
        nickName: "",
        dyAccount: "",
        href: "",
        nums: "",
        id: "",
        conectappid: [],
        type: 1,
      },
      addrules: {
        nickName: [
          { required: true, message: "请输入抖音号昵称", trigger: "blur" },
        ],
        shopNames: [
          { required: true, message: "请输入店铺名称", trigger: "blur" },
        ],
        localID: [
          { required: true, message: "请输入店铺跳转ID", trigger: "blur" },
        ],
        dyAccount: [
          { required: true, message: "请输入抖音号", trigger: "blur" },
        ],
        href: [
          {
            required: true,
            message: "请输入需要跳转的用户userid",
            trigger: "blur",
          },
        ],
        nums: [
          {
            required: true,
            message: "请输入需要推广的次数",
            trigger: "blur",
          },
        ],
        conectappid: [
          {
            required: true,
            message: "请选择关联的运营商",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择推广归属类型",
            trigger: "blur",
          },
        ],
      },
      recordTable: [],
      recordLoading: true,
      tableDataLoading: true,
      size: 1,
      totals: 0,
      carriers: [
        {
          text: "全国",
          value: "0",
        },
      ],
      addnamese: "新增",
      dialogLoadings: false,
      timese: "",
      restus: 2,
      addsprogramOrder: false,
    };
  },
  mounted() {
    var _this = this;
    _this.getsendaccounts();
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    setTimeout(() => {
      _this.getaccounts();
    }, 500);
    _this.closetimes();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    // 新增订单
    addprogram() {
      const that = this;
      this.addform.nickName = "";
      this.addform.dyAccount = "";
      this.addform.href = "";
      this.addform.nums = "";
      this.addform.shopNames = "";
      this.addform.localID = "";
      this.addform.id = "";
      this.addform.conectappid = [];
      this.addform.type = 1;
      that.programOrder = true;
      that.addnamese = "新增";
    },
    //删除订单
    delet(ids) {
      const that = this;
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/deletDyaccount",
            that.$qs.stringify({
              ids: ids,
            })
          )
          .then((datas) => {
            if (datas.data.code == 200) {
              that.$message.success("删除订单数据成功!");
            }
            that.getaccounts();
          });
      });
    },
    // 获取抖音号订单
    getaccounts() {
      const that = this;
      that.tableDataLoading = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getNewdyorders")
        .then((datas) => {
          console.log(datas);
          that.tableData = datas.data.data;
          that.tableData.forEach((element, index) => {
            console.log(element);
            console.log(index);
            if (element.type == "0") {
              that.tableData[index].types = "电商";
            } else if (element.type == "1") {
              that.tableData[index].types = "团购";
            } else {
              that.tableData[index].types = "公益";
            }
          });
          that.tableDataLoading = false;
        });
    },
    // 获取关联公众号列表和运营商
    getsendaccounts() {
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/senprogram")
        .then((datas) => {
          // this.appidlist = datas.data.data;
          for (var i = 0; i < datas.data.carrier.length; i++) {
            this.carriers.push({
              text: datas.data.carrier[i]["name"],
              value: datas.data.carrier[i]["phone"],
            });
          }
        });
    },
    // 调整优先级
    priority(id, priors) {
      this.priors = priors * 1;
      this.ids = id;
      this.priorvisible = true;
    },
    // 调整当前状态
    statuse(id, status) {
      if (status == "已完成") {
        this.status = 2;
      } else if (status == "进行中") {
        this.status = 0;
      } else {
        this.status = 1;
      }
      this.ids = id;
      this.statusvisible = true;
    },
    // 取消显示窗口
    calels(num) {
      if (num == 1) {
        this.priorvisible = false;
        this.priors = "";
      } else if (num == 2) {
        this.accountappidsvisible = false;
        this.accountappids = "";
      } else {
        this.statusvisible = false;
        this.status = "";
      }
    },
    // 确认显示窗口
    sures(num) {
      const that = this;
      if (num == 1) {
        that.status = "";
      } else {
        that.priors = "";
      }
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/updateDyaccounts",
          that.$qs.stringify({
            status: that.status,
            ids: that.ids,
          })
        )
        .then((datas) => {
          if (datas.data.code == 200) {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            that.$message.success("数据更新成功!");
          }
          that.getaccounts();
        });
    },
    // 新增窗口重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.programOrder = false;
      this.dialogLoading = false;
      this.getaccounts();
    },
    // 新增窗口提交
    submitForm(form) {
      const _that = this;
      var trpops = null;
      _that.addform.conectappid.forEach((element) => {
        if (element == 0 && _that.addform.conectappid.length > 1) {
          // _that.$message.warning('全国和运营商不可同时选择，请调整')
          // return;
          trpops = true;
        }
      });
      if (trpops == true) {
        _that.$message.warning("全国和运营商不可同时选择，请立即调整");
      } else {
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (_that.addform.type == 0) {
              _that.addform.localID =
                "snssdk2329://poi/detail/?id=" + _that.addform.localID;
            } else if (_that.addform.type == 1) {
              _that.addform.localID =
                "snssdk2329://ec_goods_detail?promotion_id=" +
                _that.addform.localID;
            } else if (_that.addform.type == 2) {
            }
            this.dialogLoading = true;
            _that.$http
              .post(
                "https://wx.xiaoyuc.com/home/admin/addDyaccounts",
                _that.$qs.stringify(_that.addform)
              )
              .then(({ data }) => {
                if (data.code == 200) {
                  _that.resetForm(form);
                  _that.$message.success("更新成功！");
                } else {
                  _that.resetForm(form);
                  _that.$message.warning(data.msg);
                }
              });
          } else {
            return false;
          }
        });
      }
    },
    // 获取导流详情
    getOrderdetail(ids, num) {
      const that = this;
      that.recordLoading = true;
      that.recordTable = [];
      that.recordvisible = true;
      that.ids = ids;
      that.size = num;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getDyOrderdetail",
          that.$qs.stringify({
            ids: ids,
            size: that.size,
            time: that.timese,
            status: that.restus,
          })
        )
        .then((datas) => {
          that.recordTable = datas.data.data;
          that.totals = datas.data.total * 1;
          that.recordLoading = false;
        });
    },
    // 分页显示
    handleCurrentChange(e) {
      this.getOrderdetail(this.ids, e);
    },
    //
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    // 编辑窗口
    editors(e) {
      console.log(e);
      const that = this;
      that.programOrder = true;
      that.addnamese = "更新";
      that.addform.nickName = e.nickName;
      that.addform.dyAccount = e.dyID;
      that.addform.href = e.indexurl;
      that.addform.id = e.id;
      that.addform.nums = e.nums;
      that.addform.shopNames = e.shopName;
      that.addform.localID = e.localID;
      that.addform.conectappid = e.conectappid;
      that.addform.type = e.type * 1;
      console.log(that.addform);
    },
    // 时间筛选
    changetimes(e) {
      console.log(this.restus);
      this.getOrderdetail(this.ids, 1);
    },
    // 类型筛选
    gonewsit(e) {
      this.getOrderdetail(this.ids, 1);
    },
    // 时间重置
    closetimes() {
      this.addDate();
      this.restus = 2;
      this.totals = 0;
    },
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month > 9 ? date.month : "0" + date.month;
      const day = date.date > 9 ? date.date : "0" + date.date;
      this.timese = date.year + "-" + newmonth + "-" + day;
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.recordse /deep/ .el-dialog__body {
  padding-bottom: 10px !important;
}
.timeset {
  position: absolute;
  top: 20px;
  right: 60px;
  display: flex;
  height: 40px;
  align-items: center;
}
.timeset > .el-radio-group {
  width: 300px;
  margin-top: 5px !important;
  height: 40px;
}
.el-radio-group {
  height: 45px !important;
  margin-top: 0px !important;
  justify-content: flex-start;
}
</style>
