<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="left">
      <div class="left_header">
        <span :style="{ backgroundColor: bg[0] }" @click="gochecks(1)"
          >用户基础信息</span
        >
        <span :style="{ backgroundColor: bg[1] }" @click="gochecks(2)"
          >用户积分调整</span
        >
        <span :style="{ backgroundColor: bg[2] }" @click="gochecks(3)"
          >用户关注进程</span
        >
        <!-- <span :style="{ backgroundColor: bg[3] }" @click="gochecks(4)"
          >用户签到进程</span
        > -->
      </div>
      <div class="left_contents">
        <el-form label-width="100px" v-if="nums == 1">
          <el-form-item label="用户openid" v-show="shows1">
            <el-input
              v-model="openid"
              placeholder="请输入需要查询的用户openid"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户昵称" v-show="shows2">
            <el-input
              v-model="nickName"
              placeholder="请输入需要查询的用户昵称"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="checks()">立即查询</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="100px" v-if="nums == 2">
          <el-form-item label="用户openid">
            <el-input
              v-model="openid"
              placeholder="请输入需要查询的用户openid"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="creats()">立即查询</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="100px" v-if="nums == 3">
          <el-form-item label="用户openid">
            <el-input
              v-model="openid"
              placeholder="请输入需要查询的用户openid"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="checksGz()">立即查询</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-form label-width="100px" v-if="nums == 4">
          <el-form-item label="用户openid">
            <el-input
              v-model="openid"
              placeholder="请输入需要查询的用户openid"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="creats()">立即查询</el-button>
          </el-form-item>
        </el-form> -->
      </div>
    </div>
    <div class="right">
      <div class="checkalls" v-loading="lodings">
        <el-form
          label-width="150px"
          :model="search"
          v-if="nums == 1 && userInfos"
        >
          <div class="titles">查询结果</div>
          <el-form-item label="头像">
            <el-avatar
              shape="square"
              :size="100"
              fit="cover"
              :src="userInfos.autUrl"
            ></el-avatar>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="userInfos.nickName" :disabled="ables"></el-input>
          </el-form-item>
          <el-form-item label="openid">
            <el-input v-model="userInfos.openid" :disabled="ables"></el-input>
          </el-form-item>
          <el-form-item label="识别标识">
            <el-input
              v-model="userInfos.before_visitorId"
              :disabled="ables"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序识别标识">
            <el-input
              v-model="userInfos.xcx_visitorId"
              :disabled="ables"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="userInfos.creat_time" disabled></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="editor()">{{ ediors }}</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="150px" v-if="nums == 2 && ponints">
          <div class="titles">查询结果</div>
          <el-form-item label="openid">
            <el-input v-model="ponints.openid" disabled></el-input>
          </el-form-item>
          <el-form-item label="现有积分">
            <el-input v-model="ponints.points" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="ponints.creat_time" disabled></el-input>
          </el-form-item>
          <el-form-item label="积分记录">
            <el-button type="success" @click="checkRecords()"
              >查看记录</el-button
            >
          </el-form-item>
          <el-form-item label="调整类型">
            <el-radio-group v-model="resource">
              <el-radio label="新增"></el-radio>
              <el-radio label="扣除"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="调整积分">
            <el-input v-model="pointsr"></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="editor2()">立即提交</el-button>
          </el-form-item>
        </el-form>
        <div v-if="nums == 3" class="tyoo" style="position: relative">
          <div class="listyu1">
            <span style="width: 50px">头像</span>
            <span style="width: 100px">昵称</span>
            <span style="width: 300px">用户openid</span>
            <span style="width: 40px">ID</span>
            <span style="width: 80px">订单状态</span>
            <span style="width: 300px">被关注openid</span>
            <span style="width: 200px">创建时间</span>
          </div>
          <div class="listyu" v-for="(item, index) in checkGzs" :key="index">
            <span style="width: 50px"
              ><el-avatar
                shape="square"
                :size="30"
                style="margin-top: 15px"
                :src="item.autUrl"
              ></el-avatar
            ></span>
            <span style="width: 100px">{{ item.nickName }}</span>
            <span style="width: 300px">{{ item.openid }}</span>
            <span style="width: 40px">{{ item.orderId }}</span>
            <span style="width: 80px" v-if="item.status == 0">未完成</span>
            <span style="width: 80px" v-else>已完成</span>
            <span style="width: 300px">{{ item.accountOpenid }}</span>
            <span style="width: 200px">{{ item.creat_time }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="积分记录"
      width="40%"
      :visible.sync="dialogTableVisible"
      center
    >
      <el-table :data="gridData" max-height="700px">
        <el-table-column
          property="goods_type"
          label="类型"
          align="center"
        ></el-table-column>
        <el-table-column
          property="creat_time"
          label="时间"
          align="center"
        ></el-table-column>
        <el-table-column label="积分变化" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.type == 2" style="color: red; font-weight: 550">
              -{{ scope.row.points }}
            </p>
            <p v-else style="color: green; font-weight: 550">
              +{{ scope.row.points }}
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openid: "",
      nickName: "",
      shows1: true,
      shows2: true,
      userInfos: "",
      ponints: "",
      pointsr: 0,
      resource: "新增",
      dialogTableVisible: false,
      gridData: [],
      checkGzs: [],

      nums: 1,
      bg: ["#77c3ef", "#fff", "#fff", "#fff"],
      ables: true,
      ediors: "立即编辑",
      webUrls: [],
      heights: "",
      heights2: "",
      heights3: "",
      lodings: false,
      addsr: "",
    };
  },
  mounted() {
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    gochecks(num) {
      this.nums = num;
      this.ables = true;
      this.nickName = "";
      this.ediors = "立即编辑";
      this.bg.forEach((element, index) => {
        this.bg[index] = "#fff";
      });
      this.bg[num * 1 - 1] = "#77c3ef";
    },
    checks() {
      const that = this;
      this.ables = true;
      this.ediors = "立即编辑";
      if (this.openid || this.nickName) {
        that.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/editorUsers",
            that.$qs.stringify({
              openid: that.openid,
              nickName: that.nickName,
            })
          )
          .then((datas) => {
            console.log(datas.data.datas);
            that.userInfos = datas.data.datas;
            that.lodings = false;
          });
      } else {
        this.$message.warning("请输入必填信息");
      }
    },
    editor() {
      const that = this;
      this.$message.warning("暂不开放");
    },
    editor2() {
      const that = this;
      that.lodings = true;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/Addpoints",
          that.$qs.stringify(
            {
                openid:that.openid,
                points:that.pointsr,
                typer:that.resource,
                nowPoints:that.ponints.points
            }
          )
        )
        .then((datas) => {
          this.$message.success("更新信息成功!");
          that.creats();
          that.lodings = false;
        });
    },
    // 调整用户积分
    creats() {
      const that = this;
      this.pointsr = 0;
      if (this.openid) {
        this.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/editorPoints",
            that.$qs.stringify({
              openid: that.openid,
            })
          )
          .then((datas) => {
            console.log(datas);
            that.ponints = datas.data.datas;
            that.lodings = false;
          });
      } else {
        this.$message.warning("请输入必填信息");
      }
    },
    // 查询积分记录
    checkRecords() {
      const that = this;
      this.$http
        .post(
          "https://dy.upuai.cn/home/index/getUserpoints",
          that.$qs.stringify({
            openid: that.openid,
          })
        )
        .then((datas) => {
          console.log(datas.data);
          that.gridData = datas.data.data;
          that.dialogTableVisible = true;
        });
    },
    // 关注记录查询
    checksGz() {
      const that = this;
      if (this.openid) {
        this.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/checksGz",
            that.$qs.stringify({
              openid: that.openid,
            })
          )
          .then((datas) => {
            console.log(datas);
            1;
            that.checkGzs = datas.data.datas;
            that.lodings = false;
          });
      } else {
        this.$message.warning("请输入必填信息");
      }
    },
  },
  watch: {
    openid(newVal, oldVal) {
      if (newVal != "") {
        this.shows2 = false;
      } else {
        this.shows2 = true;
      }
    },
    nickName(newVal, oldVal) {
      if (newVal != "") {
        this.shows1 = false;
      } else {
        this.shows1 = true;
      }
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}
.left {
  width: 30%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.right {
  width: 68%;
  height: 100%;
  background-color: #fff;
  margin-left: 2%;
  display: flex;
  position: relative;
}
.left_header {
  width: 100%;
  background-color: rgb(245, 245, 245);
}
.left_header::-webkit-scrollbar {
  display: none !important;
}
.left_header > span {
  display: inline-block;
  padding: 0 10px;
  height: 40px;
  margin: 10px 5px;
  line-height: 40px;
  font-size: 13px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.left_contents {
  flex: 1;
  margin-top: 20px;
}
.el-form {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
}
.buttons {
  text-align: center;
  margin-top: 50px;
}
.checkalls {
  width: 100%;
  height: 100%;
  position: relative;
}
.titles {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  font-weight: 550;
}
.seachers {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.show {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #bcbcbb;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.show > img {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}
.show > span {
  display: inline-block;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.tyoo {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}
.tyoo::-webkit-scrollbar {
  display: none !important;
}
.listyu {
  width: 96%;
  height: 60px;
  margin-left: 2%;
  background-color: #e7e7e7;
  margin-top: 10px;
  display: flex;
  border-radius: 10px;
}
.listyu1 {
  width: 96%;
  height: 60px;
  background-color: #e7e7e7;
  display: flex;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 2%;
}
.listyu > span,
.listyu1 > span {
  height: 100%;
  text-align: center;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}
</style>
