<template>
  <div class="accountDetail" v-loading="loadings">
    <!-- 公众号吸粉数据 -->
    <el-table
      :data="tableData"
      empty-text="暂无内容"
      style="width: 100%"
      id="out-table"
      :stripe="true"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column type="index" label="序号" align="center" width="100">
      </el-table-column>
      <el-table-column align="center" width="300"  prop="update_time" label="关注时间">
      </el-table-column>
      <el-table-column
        align="center"
        prop="nickName"
        label="昵称"
        width="300"
      >
      </el-table-column>
       <el-table-column
        align="center"
        prop="openid"
        label="openid"
      >
      </el-table-column>
    </el-table>
    <el-button class="btn1" type="primary" size="mini">导出 {{tableData.length}}</el-button>
    <el-button class="btn2" @click="backs" type="default" size="mini" > 返回</el-button>
  </div>
</template>
<script>
import FileSaver from "file-saver";
import XLSX2 from "xlsx";
import XLSX from "xlsx-style";
import pubsub from 'pubsub-js'
export default {
  name: "accountDetail",
  data() {
    return {
        tableData:[],
        loadings:true,
    };
  },
  props: ["id"],
  mounted() {
    // console.log(this)
    const that = this
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getAccountDetail",
          that.$qs.stringify({
            orderId: this.id,
          })
        )
        .then((datas) => {
          console.log(datas)
          that.tableData = datas.data.data
          that.loadings = false
        });
  },
  methods: {
    backs(){
        this.$emit('hidemys',false)
        pubsub.publish('hello',66666)
    }
  },
  beforeDestroy(){
      // pubsub.unsubscribe(this.pubId)
  }
};
</script>

<style scoped>
.accountDetail{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
}
.btn1{
    position: fixed;
    top: 90px;
    right: 60px;
    z-index: 99999;
}
.btn2{
    position: fixed;
    top: 90px;
    right: 180px;
    z-index: 99999;
}
</style>
