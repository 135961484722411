<template>
  <div class="contents">
    <div>
      <div class="headers">
        <el-button type="primary" icon="el-icon-plus" @click="addDataName"
          >添加小程序</el-button
        >
      </div>
      <!-- 小程序列表 -->
      <div class="tables">
        <el-table
          :data="tableData"
          v-if="heights"
          empty-text="暂无内容"
          style="width: 100%"
          :height="heights"
          :stripe="true"
          :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
          v-loading="tableDataLoading"
        >
          <el-table-column align="center" :show-overflow-tooltip="true" prop="dataName" label="小程序">
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="true"  prop="xcxtype" label="平台">
          </el-table-column>
          <el-table-column
            align="center"
            width="180px"
            prop="appid"
            label="APPDI"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column align="center" prop="is_flow" label="开通流量主">
          </el-table-column>
          <el-table-column
            align="center"
            prop="is_lines"
            label="使用流量主"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="true" width="200px" prop="operation" label="运营主体">
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="true" prop="phone" label="联系方式">
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="true" prop="packnum" label="打包次数">
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="true" width="200px" prop="update_time" label="更新时间">
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="250px"
          >
            <template slot-scope="scope">
              <el-button
                type="warning"
                size="mini"
                @click="edit(scope.row.appid)"
                >编辑</el-button
              >
              <el-button
                type="success"
                size="mini"
                @click="adjust(scope.row.appid)"
                >流量主</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="packsbefor(scope.row.appid, scope.row.is_flow)"
                >打包</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 小程序新增窗口 -->
      <el-dialog
        title="添加小程序"
        width="25%"
        :visible.sync="dialogFormVisible"
        center
        :close-on-click-modal="false"
      >
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :label-width="formLabelWidth"
          v-loading="dialogLoading"
        >
          <el-form-item label="小程序名称" prop="dataName">
            <el-input v-model="form.dataName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="小程序APPID" prop="appid">
            <el-input v-model="form.appid" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="小程序原始ID" prop="oldId">
            <el-input v-model="form.oldId" autocomplete="off"></el-input>
          </el-form-item>
             <el-form-item label="使用平台" prop="phone">
            <el-radio-group v-model="form.xcxtype">
              <el-radio :label="0">微信</el-radio>
              <el-radio :label="1">抖音</el-radio>
              <el-radio :label="2">支付宝</el-radio>
              <el-radio :label="3">快手</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运营平台" prop="phone">
            <el-radio-group v-model="form.platform">
              <el-radio :label="1">优普智能</el-radio>
              <el-radio :label="2">鲁卓科技</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="流量主" prop="phone">
            <el-radio-group v-model="form.is_flow">
              <el-radio :label="0">未开通</el-radio>
              <el-radio :label="1">已开通</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="使用流量主" prop="phone">
            <el-radio-group :disabled="disables" v-model="form.is_lines">
              <el-radio :label="0">不使用</el-radio>
              <el-radio :label="1">使用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运营主体" prop="operation">
            <el-input v-model="form.operation" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button
            v-if="pic == 1"
            style="margin-left: 30px"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即添加</el-button
          >
          <el-button
            v-else
            style="margin-left: 30px"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即更新</el-button
          >
        </div>
      </el-dialog>
      <!-- 提示窗口 -->
      <el-dialog
        :visible.sync="dialogtips"
        :close-on-click-modal="false"
        width="30%"
      >
        <el-result
          v-if="mptys == 1"
          icon="success"
          title="温馨提示"
          subTitle="您的打包已完成，点击下载按钮，可以下载小程序包"
        >
          <template slot="extra">
            <el-button type="primary" size="medium" @click="doumps()"
              >点击下载</el-button
            >
          </template>
        </el-result>
        <el-result
          v-else
          icon="error"
          title="错误提示"
          subTitle="您的打包失败，请稍后重新尝试"
        >
          <template slot="extra">
            <el-button @click="dialogtips = false" type="primary" size="medium"
              >返回</el-button
            >
          </template>
        </el-result>
      </el-dialog>
      <!-- 流量主广告位替换 -->
      <el-dialog
        :visible.sync="dialogflow"
        width="30%"
        title="流量主更新"
        center
        :close-on-click-modal="false"
      >
        <el-form
          :model="flowform"
          :rules="flowrules"
          ref="ruleForm"
          label-width="140px"
        >
          <el-form-item label="首页视频广告id" prop="indexvideo">
            <el-input
              v-model="flowform.indexvideo"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="首页插屏广告id" prop="indexscreen">
            <el-input
              v-model="flowform.indexscreen"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务页模板广告id" prop="taskmodel">
            <el-input
              v-model="flowform.taskmodel"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务页banner1" prop="taskbanner1">
            <el-input
              v-model="flowform.taskbanner1"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务页banner2" prop="taskbanner2">
            <el-input
              v-model="flowform.taskbanner2"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务页banner3" prop="taskbanner3">
            <el-input
              v-model="flowform.taskbanner3"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务页激励视频id" prop="taskbestir">
            <el-input
              v-model="flowform.taskbestir"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetFlow('ruleForm')">取消</el-button>
          <el-button
            style="margin-left: 30px"
            type="primary"
            @click="submitFlow('ruleForm')"
            >立即更新</el-button
          >
        </div>
      </el-dialog>
      <!-- 流量主窗口 -->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="flowdatas"
        width="75%"
      >
        <div
          id="myChart"
          v-if="datas == true"
          style="width: 100%; height: 700px"
        ></div>
        <div
          v-else
          style="
            height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: 550;
          "
        >
          暂无数据
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {
        dataName: "",
        appid: "",
        oldId: "",
        operation: "",
        phone: "",
        is_flow: 0,
        is_lines: 0,
        platform: 1,
        xcxtype:0
      },
      mptys: 1,
      adjustform: [],
      rules: {
        dataName: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appid: [
          { required: true, message: "请输入小程序APPID", trigger: "blur" },
        ],
        oldId: [
          { required: true, message: "请输入小程序原始ID", trigger: "blur" },
        ],
        operation: [
          { required: true, message: "请输入运营主体", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
      },
      formLabelWidth: "120px",
      dialogLoading: false,
      dialogtips: false,
      dialogflow: false,
      flowform: {
        indexvideo: "",
        indexscreen: "",
        taskmodel: "",
        taskbanner1: "",
        taskbanner2: "",
        taskbanner3: "",
        taskbestir: "",
        appid: "",
      },
      flowrules: {
        indexvideo: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
        indexscreen: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
        taskmodel: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
        taskbanner1: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
        taskbanner2: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
        taskbanner3: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
        taskbestir: [
          {
            required: true,
            message: "请输入小程序流量主编号",
            trigger: "blur",
          },
        ],
      },
      hrefs: "",
      disables: true,
      pic: 1,
      flowdatas: false,
      datas: true,
      mychats: "",
      heights: "",
      tableDataLoading: true,
    };
  },
  mounted() {
    this.getnewdata();
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 210;
    },
    // 获取列表数据
    getnewdata() {
      const _that = this;
      this.tableDataLoading = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getList")
        .then(({ data }) => {
          for (var i = 0; i < data.data.length; i++) {
            // data.data[i].update_time = new Date(data.data[i].update_time * 1000)
            //   .toLocaleString("chinese", { hour12: false })
            //   .replace(new RegExp("/", "g"), "-");
            if (data.data[i].is_flow == 1) {
              data.data[i].is_flow = "是";
            } else {
              data.data[i].is_flow = "否";
            }
            if (data.data[i].is_lines == 1) {
              data.data[i].is_lines = "使用";
            } else {
              data.data[i].is_lines = "不使用";
            }
            if (data.data[i].xcxtype == 0) {
              data.data[i].xcxtype = "微信";
            } else if (data.data[i].xcxtype == 1) {
              data.data[i].xcxtype = "抖音";
            } else if (data.data[i].xcxtype == 2) {
              data.data[i].xcxtype = "支付宝";
            }else if (data.data[i].xcxtype == 3){
              data.data[i].xcxtype = "快手";
            }
          }
          _that.tableData = data.data;
          _that.tableDataLoading = false;
          console.log(_that.tableData);
        });
    },
    // 新增小程序
    addDataName() {
      this.pic = 1;
      this.form = {
        dataName: "",
        appid: "",
        oldId: "",
        operation: "",
        phone: "",
        is_flow: 0,
        is_lines: 0,
        platform: 1,
        xcxtype:0
      };
      this.dialogFormVisible = true;
    },
    // 编辑小程序
    edit(appid) {
      this.pic = 2;
      var _this = this;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getdetail",
          this.$qs.stringify({
            appid: appid,
          })
        )
        .then(({ data }) => {
          console.log(data);
          _this.form = data.data;
          _this.form.is_flow = _this.form.is_flow * 1;
          _this.form.is_lines = _this.form.is_lines * 1;
          _this.form.platform = _this.form.Platform * 1;
           _this.form.xcxtype = _this.form.xcxtype * 1;
        });
      _this.dialogFormVisible = true;
    },
    // 小程序流量主
    adjust(index) {
      // this.$message.warning("开发中，敬请期待！");
      const that = this;
      that.datas = true;
      if (that.mychats != "") {
        that.mychats.dispose();
      }
      this.flowdatas = true;
      const time1 = this.fun_date(-1); //7天后的日期
      const time2 = this.fun_date(-8); //7天前的日期
      console.log(time1, time2);
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/message/test",
          this.$qs.stringify({
            appid: index,
            start_data: time2,
            end_data: time1,
          })
        )
        .then(({ data }) => {
          var datas = [];
          var datas1 = [];
          var datas2 = [];
          var datas3 = [];
          var datas4 = [];
          var datas5 = [];
          var datas6 = [];
          var datas7 = [];
          if (!data.list) {
            that.datas = false;
            return;
          }
          console.log(data);
          if (index == "wxb080aa704b3f15d8") {
            for (var i = 0; i < data.list.length / 5; i++) {
              datas.push(data.list[5 * i].date);
              datas1.push(data.list[5 * i].income / 100);
              datas2.push(data.list[5 * i + 1].income / 100);
              datas3.push(data.list[5 * i + 2].income / 100);
              datas4.push(data.list[5 * i + 3].income / 100);
              datas5.push(data.list[5 * i + 4].income / 100);
              datas7.push(
                (
                  data.list[5 * i].income / 100 +
                  data.list[5 * i + 1].income / 100 +
                  data.list[5 * i + 2].income / 100 +
                  data.list[5 * i + 3].income / 100 +
                  data.list[5 * i + 4].income / 100
                ).toFixed(2)
              );
              // console.log(data.list[ * i].date);
            }
          } else {
            for (var i = 0; i < data.list.length / 6; i++) {
              datas.push(data.list[6 * i].date);
              datas1.push(data.list[6 * i].income / 100);
              datas2.push(data.list[6 * i + 1].income / 100);
              datas3.push(data.list[6 * i + 2].income / 100);
              datas4.push(data.list[6 * i + 3].income / 100);
              datas5.push(data.list[6 * i + 4].income / 100);
              datas6.push(data.list[6 * i + 5].income / 100);
              datas7.push(
                (
                  data.list[6 * i].income / 100 +
                  data.list[6 * i + 1].income / 100 +
                  data.list[6 * i + 2].income / 100 +
                  data.list[6 * i + 3].income / 100 +
                  data.list[6 * i + 4].income / 100 +
                  data.list[6 * i + 5].income / 100
                ).toFixed(2)
              );
              // console.log(data.list[ * i].date);
            }
          }
          datas = datas.reverse();
          datas1 = datas1.reverse();
          datas2 = datas2.reverse();
          datas3 = datas3.reverse();
          datas4 = datas4.reverse();
          datas5 = datas5.reverse();
          datas6 = datas6.reverse();
          datas7 = datas7.reverse();
          that.mychats = that.$echarts.init(document.getElementById("myChart"));
          // 指定图表的配置项和数据

          var option = {
            title: {
              text: "最近8天流量主数据",
            },
            tooltip: {
              trigger: "axis",
              formatter: function (a) {
                let list = [];
                console.log(a);
                let listItem = "";
                for (var i = 0; i < a.length; i++) {
                  list.push(
                    '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                      a[i].color +
                      ';margin-right: 5px;border-radius: 50%;}"></i><span style="width:90px; display:inline-block;">' +
                      a[i].seriesName +
                      "</span>：" +
                      a[i].value +
                      "&nbsp;元"
                  );
                }
                listItem = list.join("<br>");
                return (
                  '<div class="showBox">' +
                  a[0].axisValue +
                  "<br>" +
                  listItem +
                  "</div>"
                );
              },
            },
            legend: {
              data: [
                "激励视频",
                "插屏广告",
                "原生模板广告",
                "视频广告",
                "封面广告",
                "banner广告",
                "合计",
              ],
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: datas,
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: "{value} 元",
              },
            },
            series: [
              {
                name: "激励视频",
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
                type: "line",
                data: datas1,
              },
              {
                name: "插屏广告",
                type: "line",
                data: datas2,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
              },
              {
                name: "原生模板广告",
                type: "line",
                data: datas3,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
              },
              {
                name: "视频广告",
                type: "line",
                data: datas4,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
              },
              {
                name: "封面广告",
                type: "line",
                data: datas5,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
              },
              {
                name: "banner广告",
                type: "line",
                data: datas6,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
              },
              {
                name: "合计",
                type: "line",
                data: datas7,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
              },
            ],
          };

          // 使用刚指定的配置项和数据显示图表。
          that.mychats.setOption(option);
        });
    },
    // 打包小程序
    packs(appid, flow) {
      if (flow == "否") {
        flow = 0;
      } else {
        flow = 1;
      }
      const _this = this;
      this.flowform.appid = appid;
      this.$confirm("您确认打包该小程序吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          if (flow == 0) {
            const loading = this.$loading({
              lock: true,
              text: "正在打包中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            this.$http
              .post(
                "https://wx.xiaoyuc.com/home/admin/packwechatpro",
                this.$qs.stringify(_this.flowform)
              )
              .then(({ data }) => {
                if (data.code == 200) {
                  _this.mptys = 1;
                  setTimeout(() => {
                    loading.close();
                    _this.dialogtips = true;
                  }, 2000);
                  _this.hrefs = data.data;
                } else {
                  _this.mptys = 2;
                  setTimeout(() => {
                    loading.close();
                    _this.dialogtips = true;
                  }, 2000);
                }
              });
          } else {
            this.$http
              .post(
                "https://wx.xiaoyuc.com/home/admin/getflowinfo",
                this.$qs.stringify({
                  appid: appid,
                })
              )
              .then(({ data }) => {
                console.log(data);
                if (data.data == [] || data.data == "") {
                } else {
                  _this.flowform = data.data[0];
                }
                _this.dialogflow = true;
                console.log(_this.flowform);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 新增窗口重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.form.is_flow = 0;
      this.dialogLoading = false;
      this.dialogFormVisible = false;
      this.getnewdata();
    },
    // 新增窗口提交
    submitForm(form) {
      const _that = this;
      this.$refs[form].validate((valid) => {
        console.log(valid);
        if (valid) {
          this.dialogLoading = true;
          _that.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/add",
              _that.$qs.stringify(_that.form)
            )
            .then(({ data }) => {
              console.log(data);
              if (data.code == 200) {
                _that.resetForm(form);
                _that.$message.success("添加完成！");
              } else if (data.code == 201) {
                _that.resetForm(form);
                _that.$message.success("更新成功");
              } else {
                _that.resetForm(form);
                _that.$message.error("添加失败！");
              }
            });
        } else {
          return false;
        }
      });
    },
    // 流量主更新重置
    resetFlow(form) {
      this.$refs[form].resetFields();
      this.dialogflow = false;
    },
    // 流量主更新提交
    submitFlow(form) {
      const _this = this;
      this.dialogflow = false;
      const loading = this.$loading({
        lock: true,
        text: "正在打包中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$refs[form].validate((valid) => {
        console.log(valid);
        if (valid) {
          this.flowsLoading = true;
          _this.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/packwechatpro",
              _this.$qs.stringify(_this.flowform)
            )
            .then(({ data }) => {
              _this.$refs[form].resetFields();
              console.log(data);
              setTimeout(() => {
                loading.close();
              }, 1500);
              if (data.code == 200) {
                setTimeout(() => {
                  _this.dialogtips = true;
                  _this.hrefs = data.data;
                }, 1500);
              } else {
                _this.resetFlow(form);
              }
            });
        } else {
          return false;
        }
      });
    },
    // 点击下载
    doumps() {
      window.location.href = this.hrefs;
      this.dialogtips = false;
      this.hrefs = "";
    },
    // 获取最近日期
    fun_date(aa) {
      var date1 = new Date(),
        time1 =
          date1.getFullYear() +
          "-0" +
          (date1.getMonth() + 1) +
          "-" +
          date1.getDate(); //time1表示当前时间
      // console.log(time1)
      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + aa);
      var days1 = date2.getMonth() + 1;
      var days2 = date2.getDate();
      if (days1 < 10) {
        days1 = "0" + days1;
      }
      if (days2 < 10) {
        days2 = "0" + days2;
      }
      var time2 = date2.getFullYear() + "-" + days1 + "-" + days2;
      //  console.log(time2)
      return time2;
    },
    // 整合型方法
    packsbefor(appid, flow) {
      var _this = this;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getdetail",
          this.$qs.stringify({
            appid: appid,
          })
        )
        .then(({ data }) => {
          console.log(data);
          _this.form = data.data;
          _this.form.is_flow = _this.form.is_flow * 1;
          _this.form.is_lines = _this.form.is_lines * 1;
          _this.form.platform = _this.form.Platform * 1;
          _this.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/add",
              _this.$qs.stringify(_this.form)
            )
            .then(({ data }) => {
              _this.$message.success("更新成功");
              _this.packs(appid, flow);
            });
        });
    },
  },
  watch: {
    form: {
      handler(newVal) {
        const that = this;
        console.log(newVal.is_flow);
        if (newVal.is_flow == 0) {
          that.form.is_lines = 0;
          that.disables = true;
        } else {
          that.disables = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
  overflow: hidden;
}
.contents > div {
  width: 98%;
  background-color: #fff;
  padding: 10px 1%;
}
.headers {
  width: 98%;
  height: 70px;
  background-color: #f2f2f2;
  margin: auto;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  position: relative;
}
.headers > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.tables {
  width: 98%;
  margin: auto;
  border: 1px solid #e7e7e7 !important;
  border-top: none !important;
  overflow: auto;
}
.tables /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.tables /deep/ .el-table__fixed-right-patch {
  background-color: rgb(242, 242, 242) !important;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modelList {
  width: 100%;
  max-height: 380px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.modelList::-webkit-scrollbar {
  display: none !important;
}
.modelList_detail {
  width: 49%;
  height: 230px;
  background-color: #f2f2f2;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.modelList_detail:nth-of-type(odd) {
  margin-right: 2%;
}
.modelList_detail > .el-carousel {
  width: 100%;
  height: 70%;
}
.modelList_detail > div:nth-of-type(2) {
  width: 80%;
  height: 30%;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
}
.modelList /deep/ .el-carousel__container {
  width: 100%;
  height: 100%;
}
.el-radio-group /deep/ .el-radio{
  margin-right: 20px !important;
}
</style>