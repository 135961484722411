<template>
  <div class="contents">
    <div class="logins">
      <p>小程序配置平台</p>
      <form>
        <div class="form_input">
          <p>账号：</p>
          <span>
            <input
              type="number"
              name="phone"
              v-model="phone"
              placeholder="请输入账号"
              @keyup.enter="submit"
            />
          </span>
        </div>
        <div class="form_input">
          <p>密码：</p>
          <span>
            <input
              type="password"
              name="password"
              v-model="password"
              placeholder="请输入密码"
              @keyup.enter="submit"
            />
          </span>
        </div>
        <div class="form_input">
          <input type="button" @click="submit" class="butons" value="登 录" />
        </div>
      </form>
    </div>
    <a class="bottome" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2022000176号-3</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    submit(e) {
      const that = this;
      if (this.phone == "" || this.password == "") {
        this.$message({
          message: "请检查输入的账号密码！",
          type: "error",
          duration: 1500,
          offset: 200,
        });
      } else {
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/login/login",
            this.$qs.stringify({
              phone: that.phone,
              password: that.password,
            })
          )
          .then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                message: "登陆成功！",
                type: "success",
                duration: 1500,
                offset: 200,
              });
              window.sessionStorage.setItem('id',that.phone)
              window.sessionStorage.setItem('username',data.data)
              setTimeout(()=>{
                    this.$router.push("/main");
              },1500)
            } else {
              this.$message({
                message: "账号或密码输入错误",
                type: "error",
                duration: 1500,
                offset: 200,
              });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.contents {
  background: url(../assets/img/login.png) no-repeat;
  background-size: 100% 100%;
}
.logins {
  width: 400px;
  padding: 20px !important;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(0%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
}
.logins > p {
  display: inline-block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  font-weight: 550;
  color: #fff;
}
form {
  width: 100%;
  margin-top: 30px !important;
}
.form_input {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  font-size: 22px !important;
  color: #fff;
  margin-top: 20px !important;
}
.form_input:nth-of-type(3) {
  margin-top: 40px !important;
}
.form_input > p {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form_input > span {
  display: flex;
  width: 70%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5% !important;
}
input {
  width: 90% !important;
  height: 70% !important;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 5px;
  outline-style: none !important;
  padding: 0px 10px !important;
  font-size: 16px !important;
  color: #fff;
}
.butons {
  width: 60% !important;
  height: 80% !important;
  /* color: #fff !important; */
  font-size: 22px !important;
  font-weight: 550;
  background-color: #00adff80;
  cursor: pointer;
}
.bottome{
  text-decoration: none;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 14px;
  color: #6a6a6a;
}
</style>