<template>
  <div class="contents">
    <div>
      <div class="headers">
        <el-button type="primary" icon="el-icon-plus" @click="addDataName"
          >新增邀请码</el-button
        >
      </div>
      <!-- 邀请码列表 -->
      <div class="tables">
        <el-table
          :data="tableData"
          v-if="heights"
          empty-text="暂无内容"
          style="width: 100%"
          :max-height="heights"
          :stripe="true"
          border
          :header-cell-style="{ background: '#fafafa', color: '#606266' }"
          v-loading="tableDataLoading"
        >
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            prop="id"
            label="ID"
            width="100px"
          >
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            prop="nickName"
            width="150px"
            label="昵称"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="codes"
            label="邀请码"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="goods"
            label="奖励商品"
            width="120px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="price"
            label="商品价格"
            width="120px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="phone"
            width="120px"
            label="账号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="appName"
            width="120px"
            label="所属版本"
            :show-overflow-tooltip="true"
            :filters="[
              { text: '抖音极速版', value: '抖音极速版' },
              { text: '抖音', value: '抖音' },
            ]"
            :filter-method="filterHandler"
          >
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            width="120px"
            prop="status"
            label="当前状态"
            :filters="[
              { text: '进行中', value: '进行中' },
              { text: '已完成', value: '已完成' },
              { text: '暂停', value: '暂停' },
            ]"
            :filter-method="filterHandler"
          >
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="320px"
          >
            <template slot-scope="scope">
              <el-button type="default" size="mini" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="priors(scope.row.id, scope.row.prior)"
                >优先级</el-button
              >
              <el-button
                type="success"
                size="mini"
                @click="status(scope.row.id, scope.row.status)"
                >状态</el-button
              >
              <el-button
                type="warning"
                size="mini"
                @click="deletes(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 新增邀请code窗口 -->
      <el-dialog
        title="新增邀请码"
        :visible.sync="dialogVisible"
        :center="true"
        width="30%"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户昵称" prop="nickName">
            <el-input
              v-model="ruleForm.nickName"
              placeholder="请输入用户昵称"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户手机号" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入用户绑定的手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="奖励商品" prop="goods">
            <el-input
              v-model="ruleForm.goods"
              placeholder="请输入邀请后奖励商品"
            ></el-input>
          </el-form-item>
          <el-form-item label="每日数量" prop="num">
            <el-input
              v-model="ruleForm.num"
              placeholder="请输入每日数量"
            ></el-input>
          </el-form-item>
            <el-form-item label="奖励价格" prop="price">
            <el-input
              v-model="ruleForm.price"
              placeholder="请输入邀请后奖励商品"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属平台" prop="appName">
            <el-select
              :disabled="disabops"
              v-model="ruleForm.appName"
              placeholder="请选择所属平台"
            >
              <el-option label="抖音极速版" value="1"></el-option>
              <el-option label="抖音" value="2"></el-option>
              <el-option label="快手极速版" value="3"></el-option>
              <el-option label="快手" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邀请口令" prop="codes">
            <el-input
              type="textarea"
              placeholder="请输入新用户邀请口令"
              v-model="ruleForm.codes"
              :rows="5"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">{{
              tipos
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 优先级调整窗口 -->
      <el-dialog
        title="优先级调整"
        :visible.sync="priorvisible"
        width="20%"
        center
        :close-on-click-modal="false"
      >
        <el-radio-group v-model="priorso">
          <el-radio :label="1">1级优先</el-radio>
          <el-radio :label="2">2级优先</el-radio>
          <el-radio :label="3">3级优先</el-radio>
        </el-radio-group>
        <div class="buttons">
          <el-button @click="calels(1)" type="danger">取消</el-button>
          <el-button @click="sures(1)" type="success">确认</el-button>
        </div>
      </el-dialog>
      <!-- 状态调整窗口 -->
      <el-dialog
        title="优先级调整"
        :visible.sync="statusvisible"
        width="20%"
        center
        :close-on-click-modal="false"
      >
        <el-radio-group v-model="statuso">
          <el-radio :label="0">进行中</el-radio>
          <el-radio :label="2">已完成</el-radio>
          <el-radio :label="1">暂停</el-radio>
        </el-radio-group>
        <div class="buttons">
          <el-button @click="calels(3)" type="danger">取消</el-button>
          <el-button @click="sures(3)" type="success">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      heights: "",
      tableDataLoading: false,
      dialogVisible: false,
      statusvisible: false,
      priorvisible: false,
      ruleForm: {
        nickName: "",
        phone: "",
        appName: "",
        codes: "",
        types: "",
        idus: "",
        goods: "",
        price:'',
        num:''
      },
      rules: {
        nickName: [
          { required: true, message: "请输入用户昵称", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入用户手机号", trigger: "change" },
        ],
        appName: [
          {
            required: true,
            message: "请选择邀请码归属平台",
            trigger: "change",
          },
        ],
        codes: [{ required: true, message: "请输入邀请码", trigger: "change" }],
        goods: [
          { required: true, message: "请输入奖励商品", trigger: "change" },
        ],
        price: [
          { required: true, message: "请输入奖励价格", trigger: "change" },
        ], 
        num: [
          { required: true, message: "请输入每日控制数量", trigger: "change" },
        ],
      },
      tipos: "",
      priorso: "",
      statuso: "",
      idu: "",
      disabops: false,
    };
  },
  mounted() {
    const _this = this;
    _this.resizeHeight();
    _this.getinviteCodes();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 210;
    },
    // 新增邀請碼
    addDataName() {
      this.dialogVisible = true;
      this.ruleForm.idus = "";
      this.ruleForm.types = 1;
      this.disabops = false;
      this.tipos = "立即创建";
    },
    // 获取邀请码数据
    getinviteCodes() {
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getinviteCodes")
        .then(({ data }) => {
          this.tableData = data.datas;
        });
    },
    // 提交新增邀请码
    submitForm(form) {
      const that = this;
      this.$refs[form].validate((valid) => {
        if (valid) {
          that.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/sendinviteCodes",
              that.$qs.stringify(that.ruleForm)
            )
            .then(({ data }) => {
              //   that.tableData = data.datas;
              console.log(data);
              if (data.code == 200) {
                that.$message.success(data.msg);
              } else if (data.code == 201) {
                that.$message.warning(data.msg);
              } else {
                that.$message.error(data.msg);
              }
              that.resetForm(form);
              that.getinviteCodes();
            });
        }
      });
    },
    // 重置新增窗口
    resetForm(form) {
      this.$refs[form].resetFields();
      this.ruleForm.nickName = "";
      this.ruleForm.phone = "";
      this.ruleForm.appName = "";
      this.ruleForm.codes = "";
      this.ruleForm.types = "";
      this.ruleForm.idus = "";
      this.ruleForm.goods = "";
      this.ruleForm.price = "";
      this.ruleForm.num = "";
      this.dialogVisible = false;
    },
    // 删除邀请码订单
    deletes(id) {
      const that = this;
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          that.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/deleteinviteCodes",
              that.$qs.stringify({
                id: id,
              })
            )
            .then(({ data }) => {
              if (data.code == 200) {
                that.$message.success("删除订单成功!");
                that.getinviteCodes();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑邀请订单
    edit(e) {
      console.log(e);
      this.ruleForm.nickName = e.nickName;
      this.ruleForm.phone = e.phone;
      this.ruleForm.idus = e.id;
      this.ruleForm.goods = e.goods;
      this.ruleForm.price = e.price
      this.disabops = true;
      this.ruleForm.num = e.num
      if (e.appName == "抖音") {
        this.ruleForm.appName = "2";
      } else if (e.appName == "抖音极速版") {
        this.ruleForm.appName = "1";
      } else if (e.appName == "快手") {
        this.ruleForm.appName = "4";
      } else if (e.appName == "快手极速版") {
        this.ruleForm.appName = "3";
      }
      this.ruleForm.codes = e.codes;
      this.dialogVisible = true;
      this.ruleForm.types = 2;
      this.tipos = "立即编辑";
    },
    // 调整优先级
    priors(id, priorse) {
      this.priorvisible = true;
      this.idu = id;
      this.priorso = priorse * 1;
    },
    // 调整状态
    status(id, statuss) {
      this.statusvisible = true;
      this.idu = id;
      if (statuss == "进行中") {
        this.statuso = 0;
      } else if (statuss == "已完成") {
        this.statuso = 2;
      } else {
        this.statuso = 1;
      }
    },
    // 取消调整优先级和状态
    calels(num) {
      if (num == 1) {
        this.priorvisible = false;
        this.idu = "";
        this.priorso = "";
      } else {
        this.statusvisible = false;
        this.idu = "";
        this.statuso = "";
      }
    },
    // 确认调整优先级和状态
    sures(num) {
      const that = this;
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/editStatusr",
          that.$qs.stringify({
            ids: that.idu,
            priors: that.priorso,
            status: that.statuso,
          })
        )
        .then(({ data }) => {
          if (data.code == 200) {
            that.$message.success("订单调整成功!");
            that.getinviteCodes();
            that.calels(num);
          }
        });
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
  overflow: hidden;
}
.contents > div {
  width: 98%;
  background-color: #fff;
  padding: 10px 1%;
}
.headers {
  width: 98%;
  height: 70px;
  background-color: #f2f2f2;
  margin: auto;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  position: relative;
}
.headers > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.tables {
  width: 98%;
  margin: auto;
  border: 1px solid #e7e7e7 !important;
  border-top: none !important;
  overflow: auto;
}
.tables /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.tables /deep/ .el-table__fixed-right-patch {
  background-color: rgb(242, 242, 242) !important;
}
.el-form-item__content > button {
  float: right;
  margin-right: 30px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>