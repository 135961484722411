<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <!-- 抖音号导流订单 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      :max-height="heights2"
      :stripe="true"
      v-loading="tableDataLoading"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
        fixed
        align="center"
        prop="id"
        width="80px"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column align="center" width="400px"  prop="openid" label="openid">
      </el-table-column>
      <el-table-column align="center" prop="cardId" label="卡券ID">
      </el-table-column>
      <el-table-column align="center" prop="status" label="完成状态">
      </el-table-column>
      <el-table-column align="center" prop="devices" label="设备编号">
      </el-table-column>
      <el-table-column align="center" prop="indexUrl" label="所属运营商">
      </el-table-column>
       <el-table-column align="center" prop="totals" label="计数">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      tableDataLoading: true,
    };
  },
  mounted() {
    var _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    setTimeout(() => {
      _this.getaccounts();
    }, 500);
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 140;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    // 获取小程序引流订单
    getaccounts() {
      const that = this;
      that.tableDataLoading = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/sendGoods")
        .then((datas) => {
          console.log(datas)
          that.tableData = datas.data.datas;
          that.tableDataLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.recordse /deep/ .el-dialog__body {
  padding-bottom: 10px !important;
}
.timeset {
  position: absolute;
  top: 20px;
  right: 60px;
  display: flex;
  height: 40px;
  align-items: center;
}
.timeset>.el-radio-group{
  width: 300px;
  margin-top: 5px !important;
  height: 40px;
}
</style>