<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="header">
      <el-button type="primary" icon="el-icon-plus" @click="addprogram"
        >新增商品</el-button
      >
    </div>
    <!-- 商品列表 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      :stripe="true"
      :max-height="heights2"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
        fixed
        align="center"
        prop="id"
        width="80px"
        label="商品id"
      >
      </el-table-column>
      <el-table-column align="center" prop="goods_name" label="商品名称">
      </el-table-column>
      <el-table-column
        align="center"
        width="200px"
        prop="goods_price"
        label="单价(积分)"
      >
      </el-table-column>
      <el-table-column align="center" label="图片">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            :size="45"
            :src="scope.row.goods_img"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="goods_num" label="库存">
      </el-table-column>
      <el-table-column align="center" prop="goods_status" label="状态">
      </el-table-column>
      <el-table-column align="center" width="100px" prop="types" label="类型">
      </el-table-column>
      <el-table-column
        align="center"
        width="160px"
        prop="creat_time"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="160px"
        prop="update_time"
        label="更新时间"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="400px">
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="priority(scope.row.id, scope.row.goods_num)"
            >补货</el-button
          >
          <el-button
            style="background-color: #f37c90; color: #fff"
            size="mini"
            @click="editorOrderdetail(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="relevance(scope.row.id, scope.row.goods_price)"
            >单价</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="statuse(scope.row.id, scope.row.goods_status)"
            >状态</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增商品窗口 -->
    <el-dialog
      title="新增(编辑)商品"
      :visible.sync="programOrder"
      width="25%"
      center
      :close-on-click-modal="false"
    >
      <el-form
        :model="addform"
        :rules="addrules"
        ref="ruleForm"
        label-width="130px"
        class="coyus"
      >
        <el-form-item label="商品名称" prop="goodsname">
          <el-input
            v-model="addform.goodsname"
            autocomplete="off"
            placeholder="请输入添加商品的名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="goodsprice">
          <el-input
            v-model="addform.goodsprice"
            autocomplete="off"
            placeholder="请输入添加商品的单价"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存" prop="goodsnum">
          <el-input
            v-model="addform.goodsnum"
            autocomplete="off"
            placeholder="请输入添加商品的库存"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="goodstatus">
          <el-select v-model="addform.goodstatus" placeholder="请选择">
            <el-option
              v-for="item in statuslist"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="goodstype">
          <el-select v-model="addform.goodstype" placeholder="请选择">
            <el-option
              v-for="item in typelist"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :auto-upload="true"
            name="uploads"
            :http-request="ImgUploadSectionFile"
          >
            <el-image
              fit="cover"
              v-if="styleUrl"
              :src="styleUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="submitForm('ruleForm')"
          >{{ chejks }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 补货调整窗口 -->
    <el-dialog
      title="补货调整"
      :visible.sync="priorvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="priors">
        <el-radio :label="50">50</el-radio>
        <el-radio :label="100">100</el-radio>
        <el-radio :label="200">200</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(1)" type="danger">取消</el-button>
        <el-button @click="sures(1)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 单价调整窗口 -->
    <el-dialog
      title="单价调整"
      :visible.sync="accountappidsvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-input
        v-model="goods_prices"
        autocomplete="off"
        placeholder="请输入新的单价,单位：元"
      ></el-input>
      <div class="buttons">
        <el-button @click="calels(3)" type="danger">取消</el-button>
        <el-button @click="sures(3)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 状态调整窗口 -->
    <el-dialog
      title="状态调整"
      :visible.sync="statusvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="status">
        <el-radio :label="0">上架</el-radio>
        <el-radio :label="1">下架</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(4)" type="danger">取消</el-button>
        <el-button @click="sures(4)" type="success">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      priorvisible: false,
      priors: 50,
      ids: "",
      status: "",
      types: "",
      goods_prices: "",
      statusvisible: false,
      accountappidsvisible: false,
      programOrder: false,
      typesvisible: false,
      styleUrl: "",
      filestyle: "",
      typelist: [
        {
          name: "线上商品",
          value: 1,
        },
        {
          name: "自营商品",
          value: 2,
        },
        {
          name: "合作商品",
          value: 3,
        },
      ],
      statuslist: [
        {
          name: "上架中",
          value: "0",
        },
        {
          name: "下架中",
          value: "1",
        },
      ],
      addform: {
        goodsname: "",
        goodsprice: "",
        goodsnum: "",
        goodstatus: "",
        goodstype: "",
        goodsimg: "",
        typos: 1,
      },
      addrules: {
        goodsname: [
          { required: true, message: "请输入添加商品的名称", trigger: "blur" },
        ],
        goodsprice: [
          { required: true, message: "请输入添加商品的单价", trigger: "blur" },
        ],
        goodsnum: [
          { required: true, message: "请输入添加商品的库存", trigger: "blur" },
        ],
        goodstatus: [
          { required: true, message: "请选择添加商品的状态", trigger: "blur" },
        ],
        goodstype: [
          { required: true, message: "请选择添加商品的类型", trigger: "blur" },
        ],
      },
      chejks: "立即新增",
    };
  },
  mounted() {
    var _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getaccounts();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    // 新增订单
    addprogram() {
      this.programOrder = true;
      this.addform.typos = "";
      this.addform.goodsname = '';
      this.addform.goodsprice = '';
      this.addform.goodsnum = '';
      this.addform.goodstatus = '';
      this.addform.goodstype = '';
      this.addform.goodsimg = '';
      this.styleUrl = '';
      this.chejks = "立即新增";
    },
    // 获取商品订单
    getaccounts() {
      const that = this;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getgoodlist")
        .then((datas) => {
          that.tableData = datas.data.data;
        });
    },
    // 调整补货
    priority(id, priors) {
      this.priors = priors * 1;
      this.ids = id;
      this.priorvisible = true;
    },
    // 调整类型
    editorOrderdetail(types) {
      this.programOrder = true;
      this.chejks = "立即更新";
      this.addform.goodsname = types.goods_name;
      this.addform.goodsprice = types.goods_price;
      this.addform.goodsnum = types.goods_num;
      this.addform.goodstatus = types.status;
      if (types.types == "线上商品") {
        this.addform.goodstype = 1;
      } else if (types.types == "自营商品") {
        this.addform.goodstype = 2;
      } else {
        this.addform.goodstype = 3;
      }
      this.addform.goodsimg = types.goods_img;
      this.styleUrl = types.goods_img;
      this.addform.typos = types.id;
    },
    // 调整单价
    relevance(id, appid) {
      this.ids = id;
      this.goods_prices = appid;
      this.accountappidsvisible = true;
    },
    // 调整当前状态
    statuse(id, status) {
      if (status == "上架中") {
        this.status = 0;
      } else {
        this.status = 1;
      }
      this.ids = id;
      this.statusvisible = true;
    },
    // 取消显示窗口
    calels(num) {
      if (num == 1) {
        this.priorvisible = false;
        this.priors = "";
      } else if (num == 2) {
        this.typesvisible = false;
        this.types = "";
      } else if (num == 3) {
        this.accountappids = "";
        this.accountappidsvisible = false;
      } else {
        this.statusvisible = false;
        this.status = "";
      }
    },
    // 确认显示窗口
    sures(num) {
      const that = this;
      console.log(that.accountappids);
      if (num == 1) {
        that.status = "";
        that.goods_prices = "";
        that.types = "";
      } else if (num == 2) {
        that.status = "";
        that.priors = "";
        that.goods_prices = "";
      } else if (num == 3) {
        that.status = "";
        that.priors = "";
        that.types = "";
      } else {
        that.priors = "";
        that.goods_prices = "";
        that.types = "";
      }
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/editrgoods",
          that.$qs.stringify({
            id: that.ids,
            status: that.status,
            priors: that.priors,
            types: that.types,
            goods_prices: that.goods_prices,
          })
        )
        .then((datas) => {
          if (datas.data.code == 200) {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            this.typesvisible = false;
            that.$message.success("数据更新成功!");
          } else {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            this.typesvisible = false;
            that.$message.error();
            ("数据更新失败!");
          }
          that.getaccounts();
        });
    },
    // 新增窗口重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.programOrder = false;
      this.styleUrl = "";
      this.filestyle = "";
      this.getaccounts();
    },
    // 新增窗口提交
    submitForm(form) {
      var _this = this;
      //   this.styleloading=true
      // if(this.addform.goodstype == '线上商品'){
      //   this.addform.goodstype = 1
      // }else if(tthis.addform.goodstype == '自营商品'){
      //   this.addform.goodstype = 2
      // }else{
      //   this.addform.goodstype = 3
      // }
      if (_this.filestyle == "") {
        _this.$refs[form].validate((valid) => {
          if (valid) {
            _this.$http
              .post(
                "https://wx.xiaoyuc.com/home/admin/addgoods",
                _this.$qs.stringify(_this.addform)
              )
              .then(({ data }) => {
                // if (data.code == 200) {
                //   _this.$message.success("新增风格成功！");
                // } else {
                //   _this.$message.error("新增风格失败！");
                // }
                _this.resetForm(form);
                // _this.getmodelList();
              });
          } else {
            return false;
          }
        });
      } else {
        let formData = new FormData(); //formdata格式
        formData.append("Img", _this.filestyle.file);
        this.$http
          .post("https://wx.xiaoyuc.com/home/admin/getimglist", formData)
          .then((res) => {
            if (res.data.code == 200) {
              this.addform.goodsimg =
                "https://wx.xiaoyuc.com/static/imglist/views/" + res.data.imgname;
              _this.$refs[form].validate((valid) => {
                if (valid) {
                  _this.$http
                    .post(
                      "https://wx.xiaoyuc.com/home/admin/addgoods",
                      _this.$qs.stringify(_this.addform)
                    )
                    .then(({ data }) => {
                      // if (data.code == 200) {
                      //   _this.$message.success("新增风格成功！");
                      // } else {
                      //   _this.$message.error("新增风格失败！");
                      // }
                      _this.resetForm(form);
                      // _this.getmodelList();
                    });
                } else {
                  return false;
                }
              });
            } else {
              _this.$message.error("预览图上传失败，请重新尝试！");
            }
          });
      }
    },
    // 上传图片
    ImgUploadSectionFile(param) {
      console.log(param);
      this.filestyle = param;
      this.styleUrl = URL.createObjectURL(param.file);
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.coyus /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  display: flex;
  align-items: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>