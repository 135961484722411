<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <!-- 商品列表 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      :stripe="true"
      :max-height="heights2"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
        fixed
        align="center"
        prop="nickName"
        width="120px"
        label="昵称"
      >
      </el-table-column>
      <el-table-column align="center" label="头像">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            :size="45"
            :src="scope.row.autUrl"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="200px"
        prop="goods_name"
        label="商品名称"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="80px"
        prop="goods_id"
        label="商品ID"
      >
      </el-table-column>
      <el-table-column align="center" prop="singleNumber" label="物流单号">
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态">
      </el-table-column>
      <el-table-column align="center" width="100px" prop="carId" label="卡券ID">
      </el-table-column>
      <el-table-column
        align="center"
        width="160px"
        prop="creat_time"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="160px"
        prop="send_time"
        label="发货时间"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="300px">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="sendGoods(scope.row.id)"
            >去发货</el-button
          >
          <el-button
            type="primary"
            size="mini"
            v-clipboard:copy="scope.row.addressId"
            v-clipboard:success="onCopy"
            >复制地址</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="物流单号" :visible.sync="dialogFormVisible" width="30%">
      <el-form>
        <el-form-item label="物流单号" label-width="80px">
          <el-input v-model="singleNumber" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="gosends()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      dialogFormVisible: false,
      singleNumber: "",
      ordersId: "",
    };
  },
  mounted() {
    var _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getaccounts();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    // 获取商品订单
    getaccounts() {
      const that = this;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getgoodsOrders")
        .then((datas) => {
          that.tableData = datas.data.datas;
        });
    },
    //复制链接
    onCopy(e) {
      this.$message.success("内容已复制到剪切板！");
    },
    // 发货填写物流单号
    sendGoods(ids) {
      this.dialogFormVisible = true;
      this.ordersId = ids;
      this.singleNumber = "";
    },
    // 确认发货
    gosends() {
      const _this = this;
      this.dialogFormVisible = false;
      if (this.singleNumber == "") {
        this.$message.warning("请填写正确的物流单号");
      } else {
        _this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/addgoods",
            _this.$qs.stringify({
              singleNumber: _this.singleNumber,
              ids: _this.ordersId,
            })
          )
          .then(({ data }) => {
            _this.$message.success("发货成功");
            this.dialogFormVisible = false;
            this.ordersId = '';
            this.singleNumber = "";
          });
      }
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.coyus /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  display: flex;
  align-items: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
