<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="header">
      <el-button type="primary" icon="el-icon-plus" @click="addprogram"
        >新增订单</el-button
      >
      <el-button
        type="success"
        icon="el-icon-refresh-right"
        @click="getaccounts"
        >刷新</el-button
      >
    </div>
    <!-- 小程序导流订单 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      :max-height="heights2"
      :stripe="true"
      v-loading="tableDataLoading"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
        fixed
        align="center"
        prop="id"
        width="80px"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="130px"
        prop="appName"
        label="小程序名称"
      >
      </el-table-column>
      <el-table-column align="center" width="180px" prop="appid" label="appid">
      </el-table-column>
      <el-table-column align="center" prop="counts" label="计划导流(uv)">
      </el-table-column>
      <el-table-column align="center" prop="alcounts" label="实际导流(uv)">
      </el-table-column>
      <el-table-column align="center" prop="prior" label="优先级">
      </el-table-column>
      <el-table-column align="center" prop="prices" label="单价(元)">
      </el-table-column>
      <el-table-column align="center" width="100px" prop="status" label="状态">
      </el-table-column>
      <el-table-column align="center" prop="conectappid" :show-overflow-tooltip="true" label="排除小程序">
      </el-table-column>
      <el-table-column
        align="center"
        width="170px"
        prop="creat_time"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="380px">
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="priority(scope.row.id, scope.row.prior)"
            >优先级</el-button
          >
          <el-button
            style="background-color: #f37c90; color: #fff"
            size="mini"
            @click="getOrderdetail(scope.row.id, 1)"
            >导流数据</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="statuse(scope.row.id, scope.row.status)"
            >状态</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="background-color: #4c71f5; color: #fff"
            @click="editors(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增订单窗口 -->
    <el-dialog
      title="新增订单"
      :visible.sync="programOrder"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        v-loading="dialogLoadings"
        :model="addform"
        :rules="addrules"
        ref="ruleForm"
        label-width="130px"
      >
        <el-form-item label="小程序名称" prop="appname">
          <el-input
            v-model="addform.appname"
            autocomplete="off"
            placeholder="请输入需要导流的小程序名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="小程序APPID" prop="appid">
          <el-input
            v-model="addform.appid"
            autocomplete="off"
            placeholder="请输入需要导流的小程序appid"
          ></el-input>
        </el-form-item>
        <el-form-item label="计划引流数量" prop="count">
          <el-input
            v-model="addform.count"
            autocomplete="off"
            placeholder="请输入需要导流的次数"
          ></el-input>
        </el-form-item>
        <el-form-item label="UV单价(元)" prop="prices">
          <el-input
            v-model="addform.prices"
            autocomplete="off"
            placeholder="请输入每个UV的单价"
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转地址和参数" prop="href">
          <el-input
            v-model="addform.href"
            autocomplete="off"
            placeholder="请输入跳转时的地址和参数"
          ></el-input>
        </el-form-item>
        <el-form-item label="排除小程序" prop="conectappid">
          <el-select
            v-model="addform.conectappid"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in appidlist"
              :key="item.appid"
              :label="item.name"
              :value="item.appid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="submitForm('ruleForm')"
          >{{ addnamese }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 优先级调整窗口 -->
    <el-dialog
      title="优先级调整"
      :visible.sync="priorvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="priors">
        <el-radio :label="1">1级优先</el-radio>
        <el-radio :label="2">2级优先</el-radio>
        <el-radio :label="3">3级优先</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(1)" type="danger">取消</el-button>
        <el-button @click="sures(1)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 状态调整窗口 -->
    <el-dialog
      title="优先级调整"
      :visible.sync="statusvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="status">
        <el-radio :label="0">进行中</el-radio>
        <el-radio :label="2">已完成</el-radio>
        <el-radio :label="1">暂停</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(3)" type="danger">取消</el-button>
        <el-button @click="sures(3)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 引流记录 -->
    <el-dialog
      title="引流记录"
      :visible.sync="recordvisible"
      width="70%"
      center
      class="recordse"
      :close-on-click-modal="false"
      @close="closetimes"
    >
      <div class="timeset">
          <el-radio-group @change="gonewsit" v-model="restus">
        <el-radio :label="2">全部</el-radio>
        <el-radio :label="1">已完成</el-radio>
        <el-radio :label="0">未完成</el-radio>
      </el-radio-group>
        <el-date-picker
          v-model="timese"
          @change="changetimes"
          value-format="yyyy-MM-dd"
          type="date"
          default-value="2022-03-20"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <el-table
        :data="recordTable"
        v-if="heights3"
        empty-text="暂无内容"
        :stripe="true"
        style="width: 100%"
        :max-height="heights3"
        :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
        v-loading="recordLoading"
      >
        <el-table-column :show-overflow-tooltip="true" fixed align="center" prop="id" width="80px" label="id">
        </el-table-column>
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="45"
              :src="scope.row.imgurl"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nickname" label="微信昵称">
        </el-table-column>
        <el-table-column
          align="center"
          width="180px"
          prop="openid"
          :show-overflow-tooltip="true"
          label="openid"
        >
        </el-table-column>
        <el-table-column align="center" prop="nowappid" label="使用小程序">
        </el-table-column>
        <el-table-column align="center" prop="nextappid" label="跳转小程序">
        </el-table-column>
        <el-table-column align="center" prop="orderid" label="订单编号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="operatorsId"
          label="运营商"
          :filters="carriers"
          :filter-method="filterHandler"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          prop="status"
          label="状态"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="160px"
          prop="creat_time"
          label="创建时间"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="size"
        background
        layout="prev, pager, next"
        :total="totals"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      priorvisible: false,
      priors: 3,
      ids: "",
      status: "",
      statusvisible: false,
      accountappids: "",
      accountappidsvisible: false,
      programOrder: false,
      dialogLoading: false,
      recordvisible: false,
      appidlist: [],
      addform: {
        appname: "",
        appid: "",
        count: "",
        prices: "",
        href: "",
        conectappid: [],
        id: "",
      },
      addrules: {
        appname: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appid: [
          { required: true, message: "请输入小程序APPID", trigger: "blur" },
        ],
        count: [
          { required: true, message: "请输入需要引流的数量", trigger: "blur" },
        ],
        prices: [
          { required: true, message: "请输入每个UV的单价", trigger: "blur" },
        ],
        conectappid: [
          { required: true, message: "请选择关联的小程序", trigger: "blur" },
        ],
      },
      recordTable: [],
      recordLoading: true,
      tableDataLoading: true,
      size: 1,
      totals: 0,
      carriers: [],
      addnamese: "新增",
      dialogLoadings: false,
      timese: "",
      restus:2,
    };
  },
  mounted() {
    var _this = this;
    _this.getsendaccounts();
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    setTimeout(() => {
      _this.getaccounts();
    }, 500);
    _this.closetimes();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    // 新增订单
    addprogram() {
      this.addnamese = "新增";
      this.addform.appname = "";
      (this.addform.appid = ""),
        (this.addform.count = ""),
        (this.addform.prices = ""),
        (this.addform.href = ""),
        (this.addform.conectappid = []),
        (this.addform.id = ""),
        (this.programOrder = true);
    },
    // 获取小程序引流订单
    getaccounts() {
      const that = this;
      that.tableDataLoading = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getprogramOrder")
        .then((datas) => {
          datas.data.data.forEach((element) => {
            for (var j = 0; j < element.conectappid.length; j++) {
              for (var i = 0; i < that.appidlist.length; i++) {
                if (that.appidlist[i].appid == element.conectappid[j]) {
                  element.conectappid[j] = that.appidlist[i].name;
                }
              }
            }
          });
          that.tableData = datas.data.data;
          that.tableDataLoading = false;
        });
    },
    // 获取关联公众号列表和运营商
    getsendaccounts() {
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/senprogram")
        .then((datas) => {
          this.appidlist = datas.data.data;
          for (var i = 0; i < datas.data.carrier.length; i++) {
            this.carriers.push({
              text: datas.data.carrier[i]["name"],
              value: datas.data.carrier[i]["name"],
            });
          }
        });
    },
    // 调整优先级
    priority(id, priors) {
      this.priors = priors * 1;
      this.ids = id;
      this.priorvisible = true;
    },
    // 调整当前状态
    statuse(id, status) {
      if (status == "已完成") {
        this.status = 2;
      } else if(status == '进行中') {
        this.status = 0;
      }else{
        this.status = 1;
      }
      this.ids = id;
      this.statusvisible = true;
    },
    // 取消显示窗口
    calels(num) {
      if (num == 1) {
        this.priorvisible = false;
        this.priors = "";
      } else if (num == 2) {
        this.accountappidsvisible = false;
        this.accountappids = "";
      } else {
        this.statusvisible = false;
        this.status = "";
      }
    },
    // 确认显示窗口
    sures(num) {
      const that = this;
      if (num == 1) {
        that.status = "";
        that.accountappids = "";
      } else if (num == 2) {
        that.status = "";
        that.priors = "";
      } else {
        that.accountappids = "";
        that.priors = "";
      }
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/editrprogramOrder",
          that.$qs.stringify({
            conectappid: that.accountappids,
            status: that.status,
            prior: that.priors,
            ids: that.ids,
          })
        )
        .then((datas) => {
          if (datas.data.code == 200) {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            that.$message.success("数据更新成功!");
          } else {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            that.$message.error();
            ("数据更新失败!");
          }
          that.getaccounts();
        });
    },
    // 新增窗口重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.programOrder = false;
      this.dialogLoading = false;
      this.getaccounts();
    },
    // 新增窗口提交
    submitForm(form) {
      const _that = this;
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          _that.$http
            .post(
              "https://wx.xiaoyuc.com/home/admin/addprogramOrder",
              _that.$qs.stringify(_that.addform)
            )
            .then(({ data }) => {
              if (data.code == 200) {
                _that.resetForm(form);
                _that.$message.success("添加完成！");
              } else if (data.code == 201) {
                _that.resetForm(form);
                _that.$message.success("更新成功！");
              }
            });
        } else {
          return false;
        }
      });
    },
    // 获取导流详情
    getOrderdetail(ids, num) {
      const that = this;
      that.recordLoading = true;
      that.recordTable = [];
      that.recordvisible = true;
      that.ids = ids;
      that.size = num;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getOrderdetail",
          that.$qs.stringify({
            ids: ids,
            size: that.size,
            time: that.timese,
            status:that.restus
          })
        )
        .then((datas) => {
          datas.data.data.forEach((element) => {
            for (var i = 0; i < that.appidlist.length; i++) {
              if (that.appidlist[i].appid == element.nowappid) {
                element.nowappid = that.appidlist[i].name;
              }
              if (that.appidlist[i].appid == element.nextappid) {
                element.nextappid = that.appidlist[i].name;
              }
            }
          });
          that.recordTable = datas.data.data;
          that.totals = datas.data.total * 1;
          that.recordLoading = false;
        });
    },
    // 分页显示
    handleCurrentChange(e) {
      this.getOrderdetail(this.ids, e);
    },
    //
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    // 编辑窗口
    editors(e) {
      const that = this;
      for (var j = 0; j < e.conectappid.length; j++) {
        for (var i = 0; i < that.appidlist.length; i++) {
          if (that.appidlist[i].name == e.conectappid[j]) {
            e.conectappid[j] = that.appidlist[i].appid;
          }
        }
      }
      that.programOrder = true;
      that.addnamese = "更新";
      that.addform.appname = e.appName;
      that.addform.appid = e.appid;
      that.addform.count = e.counts;
      that.addform.prices = e.prices;
      that.addform.href = e.href;
      that.addform.id = e.id;
      that.addform.conectappid = e.conectappid;
    },
    // 时间筛选
    changetimes(e) {
      console.log(this.restus)
      this.getOrderdetail(this.ids, 1);
    },
    // 类型筛选
    gonewsit(e){
        this.getOrderdetail(this.ids, 1);
    },
    // 时间重置
    closetimes() {
      this.addDate();
      this.restus=2;
      this.totals = 0;
    },
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month > 9 ? date.month : "0" + date.month;
      const day = date.date > 9 ? date.date : "0" + date.date;
      this.timese = date.year + "-" + newmonth + "-" + day;
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.recordse /deep/ .el-dialog__body {
  padding-bottom: 10px !important;
}
.timeset {
  position: absolute;
  top: 20px;
  right: 60px;
  display: flex;
  height: 40px;
  align-items: center;
}
.timeset>.el-radio-group{
  width: 300px;
  margin-top: 5px !important;
  height: 40px;
}
</style>
