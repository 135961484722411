<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="header">
      <el-button type="primary" icon="el-icon-plus" @click="addprogram"
        >新增本地好店</el-button
      >
    </div>
    <!-- 商品列表 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      :stripe="true"
      :max-height="heights2"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
      fixed
      align="center"
      type="index"
      label="ID"
      width="50">
    </el-table-column>
      <el-table-column  align="center" :show-overflow-tooltip="true" prop="shopNames" label="店铺名称">
      </el-table-column>
      <el-table-column
        align="center"
        :show-overflow-tooltip="true"
        prop="goodsName"
        label="商品名称"
      >
      </el-table-column>
      <el-table-column align="center" label="图片">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            :size="45"
            :src="scope.row.goodsImg"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商品标签">
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.goodsTag"
            :key="index"
            class="tags"
          >
            <span v-if="item == 0">随时退</span>
            <span v-else-if="item == 1">免预约</span>
            <span v-else-if="item == 2">节假日通用</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="goodsPrices" label="商品价格(元)">
      </el-table-column>
      <el-table-column align="center" prop="prices" label="曝光单价(元)">
      </el-table-column>
      <el-table-column
        align="center"
        :show-overflow-tooltip="true"
        prop="indexUrl"
        label="店铺ID"
      >
      </el-table-column>
      <el-table-column align="center" prop="num" label="需曝光次数">
      </el-table-column>
      <el-table-column align="center" prop="alenum" label="已曝光次数">
      </el-table-column>
      <el-table-column align="center" prop="prior" label="优先级">
      </el-table-column>
      <el-table-column align="center" prop="status" label="当前状态">
      </el-table-column>
      <el-table-column
        align="center"
        :show-overflow-tooltip="true"
        prop="creat_time"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="400px">
        <template slot-scope="scope">
          <el-button type="warning" size="mini" @click="priority(scope.row)"
            >编辑</el-button
          >
          <el-button
            style="background-color: #f37c90; color: #fff"
            size="mini"
            @click="getOrderdetail(scope.row.id,scope.row.prior)"
            >优先级</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="relevance(scope.row.id, scope.row.goods_price)"
            >曝光数据</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="statuse(scope.row.id, scope.row.status)"
            >状态</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增本地好店窗口 -->
    <el-dialog
      title="新增本地好店"
      :visible.sync="programOrder"
      width="25%"
      center
      :close-on-click-modal="false"
    >
      <el-form
        :model="addform"
        :rules="addrules"
        ref="ruleForm"
        label-width="130px"
        class="coyus"
      >
        <el-form-item label="店铺名称" prop="shopname">
          <el-input
            v-model="addform.shopname"
            autocomplete="off"
            placeholder="请输入店铺名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodsname">
          <el-input
            v-model="addform.goodsname"
            autocomplete="off"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="goodsprices">
          <el-input
            v-model="addform.goodsprices"
            autocomplete="off"
            placeholder="请输入商品价格"
          >
            <template slot="append">元</template></el-input
          >
        </el-form-item>
        <el-form-item label="店铺ID" prop="indexurl">
          <el-input
            v-model="addform.indexurl"
            autocomplete="off"
            placeholder="请输入店铺ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="曝光次数" prop="pokenum">
          <el-input
            v-model="addform.pokenum"
            autocomplete="off"
            placeholder="请输入曝光次数"
          ></el-input>
        </el-form-item>
        <el-form-item label="曝光单价" prop="pokeprices">
          <el-input
            v-model="addform.pokeprices"
            autocomplete="off"
            placeholder="请输入曝光单价"
          >
            <template slot="append">元</template></el-input
          >
        </el-form-item>
        <el-form-item label="特价标签" prop="goodssale">
          <el-radio-group v-model="addform.goodssale">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标签选择" prop="goodstag">
          <el-select v-model="addform.goodstag" multiple placeholder="请选择">
            <el-option
              v-for="item in taglist"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="指定运营商" prop="conectappid">
          <el-select
            v-model="addform.conectappid"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in carriers"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片" prop="imgs">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :auto-upload="true"
            name="uploads"
            :http-request="ImgUploadSectionFile"
          >
            <el-image
              fit="cover"
              v-if="styleUrl"
              :src="styleUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="submitForm('ruleForm')"
          >{{ textyus }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 优先级调整窗口 -->
    <el-dialog
      title="类型调整"
      :visible.sync="typesvisible"
      width="30%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="priors">
        <el-radio :label="1">1级</el-radio>
        <el-radio :label="2">2级</el-radio>
        <el-radio :label="3">3级</el-radio>
        <el-radio :label="4">4级</el-radio>
        <el-radio :label="5">5级</el-radio>
        <el-radio :label="6">6级</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(2)" type="danger">取消</el-button>
        <el-button @click="sures(2)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 状态调整窗口 -->
    <el-dialog
      title="状态调整"
      :visible.sync="statusvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="status">
        <el-radio :label="0">进行中</el-radio>
        <el-radio :label="1">暂停</el-radio>
        <el-radio :label="2">已完成</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(4)" type="danger">取消</el-button>
        <el-button @click="sures(4)" type="success">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      priors: 50,
      ids: "",
      status: "",
      types: "",
      carriers:[],
      statusvisible: false,
      programOrder: false,
      typesvisible: false,
      styleUrl: "",
      filestyle: "",
      textyus: "新增好店",
      addform: {
        shopname: "",
        goodsname: "",
        goodstag: "",
        goodssale: "",
        indexurl: "",
        pokenum: "",
        goodsprices: "",
        pokeprices: "",
        imgs: "",
        ids: "",
        conectappid:[]
      },
      taglist: [
        {
          name: "随时退",
          value: "0",
        },
        {
          name: "免预约",
          value: "1",
        },
        {
          name: "节假日通用",
          value: "2",
        },
      ],
      addrules: {
        shopname: [
          { required: true, message: "请输入店铺名称", trigger: "blur" },
        ],
        goodsname: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        goodstag: [
          { required: true, message: "请选择商品标签", trigger: "blur" },
        ],
        goodssale: [
          { required: true, message: "请选择是否特价", trigger: "blur" },
        ],
        indexurl: [
          { required: true, message: "请输入店铺ID", trigger: "blur" },
        ],
        pokenum: [
          { required: true, message: "请输入曝光次数", trigger: "blur" },
        ],
        goodsprices: [
          { required: true, message: "请输入商品价格", trigger: "blur" },
        ],
        pokeprices: [
          { required: true, message: "请输入曝光单价", trigger: "blur" },
        ],
        imgs: [{ required: true, message: "请上传商品图片", trigger: "blur" }],
        conectappid: [{ required: true, message: "请选择投放的运营商", trigger: "blur" }],
      },
    };
  },
  mounted() {
    var _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getsendaccounts();
    _this.getaccounts();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    // 新增订单
    addprogram() {
      this.programOrder = true;
      this.textyus = "新增好店";
      this.addform.shopname = "";
      this.addform.goodsname = "";
      this.addform.goodstag = "";
      this.addform.goodssale = "";
      this.addform.indexurl = "";
      this.addform.pokenum = "";
      this.addform.goodsprices = "";
      this.addform.pokeprices = "";
      this.addform.imgs = "";
      this.addform.ids = "";
      this.styleUrl = "";
      this.addform.conectappid = []
      this.addform.goodssale = 1;
    },
    // 获取关联公众号列表和运营商
    getsendaccounts() {
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/senprogram")
        .then((datas) => {
          // this.appidlist = datas.data.data;
          for (var i = 0; i < datas.data.carrier.length; i++) {
            this.carriers.push({
              text: datas.data.carrier[i]["name"],
              value: datas.data.carrier[i]["phone"],
            });
          }
        });
    },
    // 获取商品订单
    getaccounts() {
      const that = this;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getlocalGoods")
        .then((datas) => {
          console.log(datas);
          that.tableData = datas.data.data;
        });
    },
    // 编辑
    priority(tables) {
      console.log(tables);
      this.textyus = "编辑好店";
      this.addform.shopname = tables.shopNames;
      this.addform.goodsname = tables.goodsName;
      this.addform.goodstag = tables.goodsTag;
      this.addform.goodssale = tables.goodsSale * 1;
      this.addform.indexurl = tables.indexUrl;
      this.addform.pokenum = tables.num;
      this.addform.goodsprices = tables.goodsPrices;
      this.addform.pokeprices = tables.prices;
      this.addform.imgs = tables.goodsImg;
      this.addform.ids = tables.id;
      this.styleUrl = tables.goodsImg;
      this.addform.conectappid = tables.conectappid
      this.programOrder = true;
    },
    // 调整优先级
    getOrderdetail(id,prior) {
      console.log(prior)
      this.priors = prior*1
      this.ids = id;
      this.typesvisible = true;
    },
    // 查询曝光数据
    relevance(id, appid) {
      this.$message.warning('暂未开放')
    },
    // 调整当前状态
    statuse(id, status) {
      if (status == "进行中") {
        this.status = 0;
      } else if(status == '暂停') {
        this.status = 1;
      }else{
        this.status = 2
      }
      this.ids = id;
      this.statusvisible = true;
    },
    // 取消显示窗口
    calels(num) {
      if (num == 2) {
        this.typesvisible = false;
        this.priors = "";
      } else {
        this.statusvisible = false;
        this.status = "";
      }
    },
    // 确认显示窗口
    sures(num) {
      const that = this;
      console.log(that.accountappids);
     if (num == 2) {
        that.status = "";
      } else {
        that.priors = "";
      }
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/editorlocals",
          that.$qs.stringify({
            id: that.ids,
            status: that.status,
            priors: that.priors
          })
        )
        .then((datas) => {
          if (datas.data.code == 200) {
            that.typesvisible = false;
            that.statusvisible = false;
            that.$message.success(datas.data.msg);
          } else {
            that.typesvisible = false;
            that.statusvisible = false;
            that.$message.error("数据更新失败!");
          }
          that.getaccounts();
        });
    },
    // 新增窗口重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.programOrder = false;
      this.styleUrl = "";
      this.filestyle = "";
      this.getaccounts();
    },
    // 新增窗口提交
    submitForm(form) {
      var _this = this;
      if (_this.addform.ids == "") {
        if(_this.filestyle == ''){
          this.$message.error('请填写必要信息!')
          return;
        }
        let formData = new FormData(); //formdata格式
        formData.append("Img", _this.filestyle.file);
        this.$http
          .post("https://wx.xiaoyuc.com/home/admin/getimglist", formData)
          .then((res) => {
            if (res.data.code == 200) {
              this.addform.imgs = res.data.imgname;
              _this.$refs[form].validate((valid) => {
                if (valid) {
                  _this.$http
                    .post(
                      "https://wx.xiaoyuc.com/home/admin/addlocals",
                      _this.$qs.stringify(_this.addform)
                    )
                    .then(({ data }) => {
                       _this.$message.success("新增本地好店成功");
                    _this.resetForm(form);
                    });
                } else {
                  return false;
                }
              });
            } else {
              _this.$message.error("商品图上传失败，请重新尝试！");
            }
          });
      } else {
        _this.$refs[form].validate((valid) => {
          if (valid) {
            _this.$http
              .post(
                "https://wx.xiaoyuc.com/home/admin/addlocals",
                _this.$qs.stringify(_this.addform)
              )
              .then(({ data }) => {

                  _this.$message.success("编辑本地好店成功");
                  _this.resetForm(form);
              });
          } else {
            return false;
          }
        });
      }
    },
    // 上传图片
    ImgUploadSectionFile(param) {
      console.log(param);
      this.filestyle = param;
      this.styleUrl = URL.createObjectURL(param.file);
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.tags {
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 90%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 3%;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.coyus /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  display: flex;
  align-items: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>