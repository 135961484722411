<template>
  <div class="contents">
    <div>
      <div class="headers">
        <el-button type="primary" icon="el-icon-plus" @click="addDataName"
          >添加推广视频</el-button
        >
      </div>
      <!-- t推广视频列表 -->
      <div class="tables">
        <el-table
          :data="tableData"
          v-if="heights"
          empty-text="暂无内容"
          style="width: 100%"
          :max-height="heights"
          :stripe="true"
          :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
          v-loading="tableDataLoading"
        >
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            prop="id"
            label="订单编号"
            width="100px"
          >
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            prop="text"
            label="视频标题"
          >
          </el-table-column>
          <el-table-column
            align="center"
            width="180px"
            prop="poi_name"
            label="地理位置"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column align="center" prop="is_flow" label="自定义封面">
            <template slot-scope="scope">
              <el-image
                style="width: 60px; height: 60px"
                :src="scope.row.custom_cover_image_url"
                :preview-src-list="[scope.row.custom_cover_image_url]"
              >
          </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="show_num"
            label="需要推广次数"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            width="200px"
            prop="alert_num"
            label="已推广次数"
          >
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            prop="show_monery"
            label="推广单价(元)"
          >
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            width="180px"
            prop="creat_time"
            label="创建时间"
          >
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="320px"
          >
            <template slot-scope="scope">
              <el-button
                type="warning"
                size="mini"
                @click="lookvideo(scope.row)"
                >查看视频</el-button
              >
              <el-button type="default" size="mini" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="priors(scope.row.appid, scope.row.is_flow)"
                >优先级</el-button
              >
              <el-button
                type="success"
                size="mini"
                @click="status(scope.row.appid, scope.row.is_flow)"
                >状态</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 推广视频新增窗口 -->
      <el-dialog
        title="新增推广视频"
        width="30%"
        :visible.sync="dialogFormVisible"
        center
        :close-on-click-modal="false"
      >
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :label-width="formLabelWidth"
          v-loading="dialogLoading"
        >
          <el-form-item label="视频标题" prop="title">
            <el-input
              v-model="form.title"
              autocomplete="off"
              placeholder="请输入推广视频的标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="地理位置名称" prop="address">
            <el-input
              v-model="form.address"
              autocomplete="off"
              placeholder="请输入推广视频的地点"
            ></el-input>
          </el-form-item>
            <el-form-item label="地理位置的POI" prop="POIs">
            <el-input
              v-model="form.POIs"
              autocomplete="off"
              placeholder="请输入推广视频地点的POI"
              style="width:80%"
            ></el-input>
             <el-button style="height:30px;margin-left:10px;line-height:30px;padding:0px;width:50px;font-size:14px" type="primary"  @click="seacherPoi"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item label="需要推广次数" prop="need_num">
            <el-input
              v-model="form.need_num"
              autocomplete="off"
              placeholder="请输入需要推广的次数"
            ></el-input>
          </el-form-item>
          <el-form-item label="推广单价(元/次)" prop="prices">
            <el-input
              v-model="form.prices"
              autocomplete="off"
              placeholder="请输入推广的单价"
            ></el-input>
          </el-form-item>
          <el-form-item label="自定义封面">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :auto-upload="true"
              name="uploads"
              :http-request="ImgUploadSectionFile"
            >
              <el-image
                fit="cover"
                v-if="styleUrl"
                :src="styleUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="视频素材">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="true"
              name="uploads"
              :http-request="getstylefiles"
            >
              <video
                v-if="videoUrl"
                autoplay
                controls
                :src="videoUrl"
                class="videos"
              ></video>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button
            v-if="pic == 1"
            style="margin-left: 30px"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即添加</el-button
          >
          <el-button
            v-else
            style="margin-left: 30px"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即更新</el-button
          >
        </div>
      </el-dialog>
      <!-- 视频预览窗口 -->
      <el-dialog
        title="预览视频素材"
        width="50%"
        :visible.sync="videoShows"
        center
        :close-on-click-modal="false"
      >
        <video
          autoplay
          muted
          controls
          style="
            width: 96%;
            height: 500px;
            background-color: rgba(0, 0, 0, 0.5);
          "
          :src="videoSrcs"
        ></video>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {
        title: "",
        address: "",
        need_num: "",
        prices: "",
        imgurl: "",
        videoUrls: "",
        POIs:''
      },
      mptys: 1,
      rules: {
        title: [
          { required: true, message: "请输入推广视频的标题", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入推广视频的地点", trigger: "blur" },
        ],
        need_num: [
          { required: true, message: "请输入需要推广的次数", trigger: "blur" },
        ],
        prices: [
          { required: true, message: "请输入推广的单价", trigger: "blur" },
        ],
        POIs:[
          { required: true, message: "请输入推广视频地点的POI", trigger: "blur" },
        ]
      },
      formLabelWidth: "150px",
      dialogLoading: false,
      pic: 1,
      heights: "",
      tableDataLoading: true,
      styleUrl: "", //图片预览地址
      filestyle: "", //图片源文件
      videstyle: "", //视频源文件
      videoUrl: "", //视频预览地址
      videoSrcs: "",
      videoShows: false,
    };
  },
  mounted() {
    this.getnewdata();
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 210;
    },
    // 获取列表数据
    getnewdata() {
      const _that = this;
      this.tableDataLoading = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getextends")
        .then(({ data }) => {
          _that.tableData = data.data;
          _that.tableDataLoading = false;
        });
    },
    // 新增小程序
    addDataName() {
      this.pic = 1;
      this.form = {
        title: "",
        address: "",
        need_num: "",
        prices: "",
        imgurl: "",
        videoUrls: "",
      };
      this.dialogFormVisible = true;
    },
    // 编辑小程序
    edit(appid) {
      this.$message.warning("暂未上线");
      return;
      this.pic = 2;
      var _this = this;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getdetail",
          this.$qs.stringify({
            appid: appid,
          })
        )
        .then(({ data }) => {
          console.log(data);
          _this.form = data.data;
          _this.form.is_flow = _this.form.is_flow * 1;
          _this.form.is_lines = _this.form.is_lines * 1;
          _this.form.platform = _this.form.Platform * 1;
          _this.form.xcxtype = _this.form.xcxtype * 1;
        });
      _this.dialogFormVisible = true;
    },
    // 新增窗口重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.styleUrl = "";
      this.filestyle = "";
      this.videoUrl = "";
      this.videstyle = "";
      this.dialogLoading = false;
      this.dialogFormVisible = false;
      this.getnewdata();
    },
    // 新增窗口提交
    submitForm(form) {
      const _that = this;
      if (this.videstyle == "") {
        this.$message.error("请上传需要推广的视频");
        return;
      }
      let formData = new FormData(); //formdata格式
      formData.append("Img", _that.filestyle.file);
      formData.append("Video", _that.videstyle.file);
      this.$refs[form].validate((valid) => {
        console.log(valid);
        if (valid) {
          _that.$http
            .post("https://wx.xiaoyuc.com/home/admin/addextends", formData)
            .then((res) => {
              if (res.data.code == 200) {
                console.log(res);
                _that.form.imgurl = res.data.data.imgaddress;
                _that.form.videoUrls = res.data.data.videoaddress;
                this.dialogLoading = true;
                _that.$http
                  .post(
                    "https://wx.xiaoyuc.com/home/admin/addvideos",
                    _that.$qs.stringify(_that.form)
                  )
                  .then(({ data }) => {
                    console.log(data);
                    if (data.code == 200) {
                      _that.resetForm(form);
                      _that.$message.success("添加完成！");
                    } else if (data.code == 201) {
                      _that.resetForm(form);
                      _that.$message.success("更新成功");
                    } else {
                      _that.resetForm(form);
                      _that.$message.error("添加失败！");
                    }
                  });
              } else {
                _that.$message.error("上传视频失败，请稍后再试");
              }
            });
        }
      });
    },
    // 获取自定义封面
    ImgUploadSectionFile(param) {
      console.log(param);
      this.filestyle = param;
      this.styleUrl = URL.createObjectURL(param.file);
    },
    // 获取视频素材
    getstylefiles(param) {
      console.log(param);
      this.videstyle = param;
      this.videoUrl = URL.createObjectURL(param.file);
    },
    // 查看视频
    lookvideo(e) {
      console.log(e);
      this.videoShows = true;
      this.videoSrcs = e.video;
      // this.$message.warning('暂未上线')
    },
    // 调整优先级
    priors() {
      this.$message.warning("暂未上线");
    },
    // 调整优先级
    status() {
      this.$message.warning("暂未上线");
    },
    // 查询poi
    seacherPoi(){
      window.open('https://lbs.amap.com/demo/amap-ui/demos/amap-ui-poipicker/index/')
    }
  },
};
</script>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
  overflow: hidden;
}
.contents > div {
  width: 98%;
  background-color: #fff;
  padding: 10px 1%;
}
.headers {
  width: 98%;
  height: 70px;
  background-color: #f2f2f2;
  margin: auto;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  position: relative;
}
.headers > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.tables {
  width: 98%;
  margin: auto;
  border: 1px solid #e7e7e7 !important;
  border-top: none !important;
  overflow: auto;
}
.tables /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.tables /deep/ .el-table__fixed-right-patch {
  background-color: rgb(242, 242, 242) !important;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modelList {
  width: 100%;
  max-height: 380px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.modelList::-webkit-scrollbar {
  display: none !important;
}
.modelList_detail {
  width: 49%;
  height: 230px;
  background-color: #f2f2f2;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.modelList_detail:nth-of-type(odd) {
  margin-right: 2%;
}
.modelList_detail > .el-carousel {
  width: 100%;
  height: 70%;
}
.modelList_detail > div:nth-of-type(2) {
  width: 80%;
  height: 30%;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
}
.modelList /deep/ .el-carousel__container {
  width: 100%;
  height: 100%;
}
.el-radio-group /deep/ .el-radio {
  margin-right: 20px !important;
}
.avatar-uploader {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 158px;
  height: 158px;
  line-height: 158px;
  text-align: center;
  border: 1px dashed #ccc;
  border-radius: 10px;
}
.avatar {
  width: 158px;
  height: 158px;
  display: block;
}
.videos {
  width: 250px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
</style>