<template>
  <div class="contents">
    <div class="headers">
      <el-button type="primary" icon="el-icon-plus" @click="addmodel"
        >添加模板</el-button
      >
    </div>
    <div v-if="forms.length > 0" :style="{ height: heights + 'px' }">
      <div class="model_list" v-for="(item, index) in forms" :key="index">
        <div>
          <el-image :src="item.modelimg"></el-image>
        </div>
        <div class="modeldec">
           <span>
            <p class="model_title">名称：</p>
            <p>{{ item.modelname }}</p>
          </span>
          <span>
            <p class="model_title">文件名称：</p>
            <p>{{ item.filename }}</p>
          </span>
          <span>
            <p class="model_title">创建时间：</p>
            <p>{{ item.creat_time }}</p>
          </span>
          <span>
            <p class="model_title">流量主：</p>
            <p v-if="item.is_flow == 0">无</p>
            <p v-else>有</p>
          </span>
          <span>
            <p class="model_title">作者：</p>
            <p>{{ item.modeldesigner }}</p>
          </span>
          <span
            ><p class="model_title">描述：</p>
            <p>
              {{ item.modeldec }}
            </p></span>
          <span>
            <p v-if="item.filename == ''">
              <el-button
                type="primary"
                round
                size="mini"
                @click="upfiles(item.id)"
                >上传</el-button
              >
            </p>
             <p v-else>
              <el-button
                type="primary"
                round
                size="mini"
                @click="upfiles(item.id)"
                >更新</el-button
              >
            </p>
            <p>
              <el-button
                type="danger"
                round
                size="mini"
                @click="deletes(item.id)"
                >删除</el-button
              >
            </p>
          </span>
        </div>
        <!-- 添加模板窗口 -->
        <el-dialog
          title="添加模板"
          width="25%"
          :visible.sync="dialogFormVisible"
          center
        >
          <el-form
            :model="form"
            :rules="rules"
            ref="ruleForm"
            :label-width="formLabelWidth"
            v-loading="dialogLoading"
            enctype="multipart/form-data"
          >
            <el-form-item label="模板名称" prop="dataName">
              <el-input v-model="form.dataName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="模板设计师" prop="desgir">
              <el-input v-model="form.desgir" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="流量主" prop="is_flow">
              <el-radio-group v-model="form.is_flow">
                <el-radio :label="0">不支持</el-radio>
                <el-radio :label="1">支持</el-radio>
              </el-radio-group>
            </el-form-item>
              <el-form-item label="文件名称" prop="filename">
              <el-input v-model="form.filename" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="模板描述" prop="dec">
              <el-input v-model="form.dec" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="模板设计图" prop="imglist">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :auto-upload="true"
                name="uploads"
                :http-request="ImgUploadSectionFile"
              >
                <el-image
                  fit="cover"
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="submitForm('ruleForm')"
              >立即添加</el-button
            >
          </div>
        </el-dialog>
        <!-- 上传模板文件 -->
        <el-dialog
          title="请上传模板文件"
          width="25%"
          :visible.sync="dialogfile"
          center
        >
          <el-form
            v-loading="fileloading"
            enctype="multipart/form-data"
          >
            <el-form-item prop="imglist">
             <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :auto-upload="true"
                name="uploads"
                :http-request="getmodelfiles"
              >
                <el-image
                  fit="cover"
                  v-if="fileurls"
                  :src="fileurls"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
             </el-upload>
               <span style="display:inline-block;width:100%;text-align:center;margin-top:20px;font-weight:550;color:red">注意：仅支持压缩包文件！！！</span>
               <span style="display:inline-block;width:100%;text-align:center;font-weight:550;color:red">注意：文件名要和添加模板的名称一致！</span>
               <span style="display:inline-block;width:100%;text-align:center;font-weight:550;color:red">注意：有无流量主版本文件名请进行区分！</span>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="resitforms()">取消</el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="upforms()"
              >立即上传</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
    <el-empty v-else :style="{ height: imgheights + 'px' }" :image-size="200">
      <!-- 添加模板窗口 -->
      <el-dialog
        title="添加模板"
        width="25%"
        :visible.sync="dialogFormVisible"
        center
      >
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :label-width="formLabelWidth"
          v-loading="dialogLoading"
          enctype="multipart/form-data"
        >
          <el-form-item label="模板名称" prop="dataName">
            <el-input v-model="form.dataName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="模板设计师" prop="desgir">
            <el-input v-model="form.desgir" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="流量主" prop="is_flow">
            <el-radio-group v-model="form.is_flow">
              <el-radio :label="0">不支持</el-radio>
              <el-radio :label="1">支持</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="模板描述" prop="dec">
            <el-input v-model="form.dec" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="模板设计图" prop="imglist">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :auto-upload="true"
              name="uploads"
              :http-request="ImgUploadSectionFile"
            >
              <el-image
                fit="cover"
                v-if="imageUrl"
                :src="imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button
            style="margin-left: 30px"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即添加</el-button
          >
        </div>
      </el-dialog>
    </el-empty>
  </div>
</template>


<script>
export default {
  data() {
    return {
      heights: "",
      forms: [],
      dialogFormVisible: false,  //添加模板的窗口
      form: {
        dataName: "",
        desgir: "",
        dec: "",
        imglist: "",
        is_flow: 0,
        filename:''
      },
      formLabelWidth: "120px",
      rules: {
        dataName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        desgir: [
          { required: true, message: "请输入模板作者", trigger: "blur" },
        ],
        dec: [{ required: true, message: "请编写模板描述", trigger: "blur" }],
        imglist: [
          { required: true, message: "请上传模板预览图", trigger: "blur" },
        ],
        is_flow: [
          { required: true, message: "请选择是否支持流量主", trigger: "blur" },
        ],
         filename: [
          { required: true, message: "请填写项目文件名称", trigger: "blur" },
        ],
      },
      imageUrl: "",              //展示的url地址
      dialogLoading: false,     //天机木板的加载动画
      files: "",              //模板添加存储的图片文件
      imgheights: "",         //无数据模块的高度
      dialogfile: false,     //上传模板的窗口
      modelfiles: "",       //存储需要上传的模板文件
      fileurls:'',          //模板和风格文件添加成功反馈的图片
      fileloading:false,   //模板文件上传加载动画
      model:'',              //上传文件带着上传的model
    };
  },
  mounted() {
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getmodelList();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      var height1 = Math.ceil((this.forms.length * 1) / 4) * 320;
      console.log(height1);
      this.heights = document.body.clientHeight * 1 - 160;
      this.imgheights = document.body.clientHeight * 1 - 250;
      if (height1 > this.heights) {
      } else {
        this.heights = height1;
      }
    },
    // 新增模板
    addmodel() {
      this.dialogFormVisible = true;
    },
    // 取消新增窗口
    resetForm(form) {
      this.files = "";
      this.imageUrl = "";
      this.form.dataName = "";
      this.form.desgir = "";
      this.form.dec = "";
      this.form.imglist = "";
      this.form.is_flow = 0;
      this.dialogFormVisible = false;
    },
    // 提交新增模板
    submitForm(form) {
      let formData = new FormData(); //formdata格式
      var _this = this;
      formData.append("Img", this.files.file);
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getimglist",
          formData
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.form.imglist = res.data.imgname;
            _this.$refs[form][0].validate((valid) => {
              if (valid) {
                _this.$http
                  .post(
                    "https://wx.xiaoyuc.com/home/admin/getmodels",
                    _this.$qs.stringify(_this.form)
                  )
                  .then(({ data }) => {
                    if (data.code == 200) {
                      _this.$message.success("新增模板成功！");
                    } else {
                      _this.$message.error("新增模板失败！");
                    }
                    _this.resetForm(form);
                    _this.getmodelList();
                  });
              } else {
                return false;
              }
            });
          } else {
            _this.$message.error("预览图上传失败，请重新尝试！");
          }
        });
    },
    // 图片上传方法
    ImgUploadSectionFile(param) {
      console.log(param);
      this.files = param;
      this.imageUrl = URL.createObjectURL(param.file);
      console.log(this.imageUrl);
      return;
      formData.append("Img", param.file);
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getimglist",
          formData
        )
        .then((res) => {
          if (res.code == 200) {
            //成功
            console.log(res);
          }
        });
    },
    // 获取列表数据
    getmodelList() {
      const that = this;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getmodelLsit")
        .then(({ data }) => {
          if (data.code == 200) {
            //成功
            that.forms = data.data;
            that.resizeHeight();
          }
        });
    },
    // 上传模板文件
    upfiles(id) {
      this.dialogfile = true;
      this.model = id;
    },
    // 删除模板
    deletes(id) {
      const _this = this;
      this.$message.warning("该方法暂未上线，敬请期待！");
      // this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   center:true
      // })
      //   .then(() => {
      //     this.$http
      //       .post(
      //         "https://wx.xiaoyuc.com/home/admin/deletemodel",
      //         this.$qs.stringify({
      //           id: id,
      //         })
      //       )
      //       .then(({ data }) => {
      //         console.log(data)
      //         if(data.code == 200){
      //           _this.$message.success('删除模板成功！');
      //         }else{
      //           _this.$message.error('删除模板失败,请稍后再试！');
      //         }
      //         _this.getmodelList();
      //       });
      //   })
      //   .catch(() => {
      //     _this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
    },
    // 选中模板文件文件
    getmodelfiles(param) {
      console.log(param);
      this.modelfiles = param.file;
      this.fileurls = 'https://wx.xiaoyuc.com/static/wechats/models/success.png'
    },
    // 取消上传模板文件
    resitforms() {
      this.fileurls = '';
      this.model = '';
      this.modelfiles = "";
      this.dialogfile = false;
    },
    // 提交模板文件方法
    upforms() {
      const that = this
      if (this.modelfiles == "") {
        this.$message.error("文件已失效，请重新上传文件！");
      } else {
         that.fileloading = true
        let formData = new FormData(); //formdata格式
        formData.append("Img", this.modelfiles);
        formData.append("model", this.model);
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/getmodelfile",
            formData
          )
          .then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              //成功
              console.log(res);
              setTimeout(()=>{
                that.fileloading = false
                that.resitforms();
                that.$message.success('上传模板文件成功！')
              },2000)
            }
          });
      }
    },
  },
};
</script>


<style scoped>
.contents {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
}
.contents > div {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.contents > div::-webkit-scrollbar {
  display: none !important;
}
.model_list {
  width: 23%;
  height: 300px;
  background-color: #fff;
  margin-right: 1%;
  margin-top: 10px;
  display: flex;
  padding: 5px 0.5%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 1px 1px #e6e6e6;
}
.model_list:last-child {
  margin-bottom: 10px;
}
.model_list > div {
  flex: 1;
}
.el-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.modeldec {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.modeldec > span {
  height: 35px;
  display: flex;
  line-height: 35px;
  overflow: hidden;
  font-size: 14px;
}
.modeldec > span > p:nth-of-type(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.modeldec > span:nth-of-type(6) {
  height: 115px !important;
  margin-top: 5px;
}
.modeldec > span:nth-of-type(6) > p:nth-of-type(1) {
  min-width: 50px;
}
.modeldec > span:nth-of-type(6) > p:nth-of-type(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 115px;
  line-height: 20px;
  font-size: 14px;
}
.modeldec > span:nth-of-type(7) {
  height: 50px !important;
  display: flex;
}
.model_title {
  font-weight: 550;
  min-width: 50px;
  font-size: 15px;
}
p {
  margin: 0% !important;
}
.modeldec > span:nth-of-type(7) > p {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headers {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.headers > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.contents /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.imglistshows /deep/ .el-dialog {
  margin-top: 100px !important;
  background: none !important;
  box-shadow: none !important;
}
.imglistshows /deep/ .el-dialog__close {
  color: #fff !important;
  font-size: 25px !important;
}
</style>