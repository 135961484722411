<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="left">
      <div class="left_header">
        <span :style="{ backgroundColor: bg[0] }" @click="gochecks(1)"
          >查询小程序地址</span
        >
        <span :style="{ backgroundColor: bg[1] }" @click="gochecks(2)"
          >生成快手二维码</span
        >
        <span :style="{ backgroundColor: bg[2] }" @click="gochecks(3)"
          >新增小程序地址</span
        >
      </div>
      <div class="left_contents">
        <el-form label-width="80px" v-if="nums == 1">
          <el-form-item label="设备编号">
            <el-input
              v-model="devices1"
              placeholder="请输入需要查询的设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="checks()">立即查询</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="80px" v-if="nums == 2">
          <el-form-item label="设备编号">
            <el-input
              v-model="devices2"
              placeholder="请输入需要生成二维码的设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="creats()">立即生成</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="130px" v-if="nums == 3">
          <el-form-item label="设备编号">
            <el-input
              v-model="search2.devices"
              placeholder="请输入需要新增的设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序地址">
            <el-input
              v-model="search2.ks_url"
              placeholder="请输入需要新增的小程序地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="极速版小程序地址">
            <el-input
              v-model="search2.kslite_url"
              placeholder="请输入需要新增的极速版小程序地址"
            ></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="adds()">立即新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="right">
      <div class="checkalls" v-loading="lodings">
        <el-form
          label-width="150px"
          :model="search"
          v-if="nums == 1 && search.devices"
        >
          <div class="titles">查询结果</div>
          <el-form-item label="设备编号">
            <el-input v-model="search.devices" disabled></el-input>
          </el-form-item>
          <el-form-item label="小程序地址">
            <el-input v-model="search.ks_url" :disabled="ables"></el-input>
          </el-form-item>
          <el-form-item label="极速版小程序地址">
            <el-input v-model="search.kslite_url" :disabled="ables"></el-input>
          </el-form-item>
          <el-form-item label="H5页面路径">
            <el-input v-model="search.webUrl" disabled></el-input>
          </el-form-item>
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="editor()">{{ ediors }}</el-button>
          </el-form-item>
        </el-form>
        <div class="seachers" v-if="nums == 2 && webUrls">
          <div class="show" v-for="(item, index) in webUrls" :key="index">
            <span>设备编号：{{ item.device }}</span>
            <!-- <img :src="item.webUrl" /> -->
            <div ref="content" class="qrcodess">
              <div>
                <qrcodes
                  :url="item.webPath"
                  :iconurl="qrcodeVue.logo"
                  :wid="300"
                  :hei="300"
                  :imgwid="70"
                  :imghei="70"
                />
              </div>
              <p>{{ item.device }}</p>
            </div>
            <el-button
              type="primary"
              icon="el-icon-document"
              size="small"
              style="margin:20px 125px;width:100px"
              @click="downloadCode(item.device)"
              >下载</el-button
            >
            <span>路径：{{ item.webPath }}</span>
          </div>
        </div>
        <el-form
          label-width="150px"
          :model="search"
          v-if="nums == 3 && addsr == 1"
        >
          <div class="titles">查询结果</div>
          <el-form-item label="设备编号">
            <el-input v-model="search.devices" disabled></el-input>
          </el-form-item>
          <el-form-item label="小程序地址">
            <el-input v-model="search.ks_url" :disabled="ables"></el-input>
          </el-form-item>
          <el-form-item label="极速版小程序地址">
            <el-input v-model="search.kslite_url" :disabled="ables"></el-input>
          </el-form-item>
          <el-form-item label="H5页面路径">
            <el-input v-model="search.webUrl" disabled></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import qrcodes from "vue_qrcodes";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      devices1: "",
      devices2: "",
      nums: 1,
      bg: ["#77c3ef", "#fff", "#fff"],
      ables: true,
      ediors: "立即编辑",
      search: {
        ks_url: "",
        kslite_url: "",
        webUrl: "",
        devices: "",
      },
      search2: {
        ks_url: "",
        kslite_url: "",
        webUrl: "",
        devices: "",
      },
      webUrls: [],
      heights: "",
      heights2: "",
      heights3: "",
      lodings: false,
      addsr: "",
      qrcodeVue: {
        size: 300,
        bgColor: "#000", //背景色
        fgColor: "#FFFFFF", //前景色
        logo: require('../assets/img/ks.jpg'), //logo图片
      },
    };
  },
  components: { qrcodes },
  mounted() {
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    gochecks(num) {
      this.nums = num;
      this.devices1 = "";
      this.devices2 = "";
      this.ables = true;
      this.ediors = "立即编辑";
      this.search.ks_url = "";
      this.search.kslite_url = "";
      this.search.webUrl = "";
      this.search.devices = "";
      this.search2.ks_url = "";
      this.search2.kslite_url = "";
      this.search2.webUrl = "";
      this.search2.devices = "";
      this.addsr = "";
      this.bg.forEach((element, index) => {
        this.bg[index] = "#fff";
      });
      this.bg[num * 1 - 1] = "#77c3ef";
    },
    checks() {
      const that = this;
      this.ables = true;

      this.ediors = "立即编辑";
      if (this.devices1) {
        that.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/getDevicexcx",
            that.$qs.stringify({
              deviceId: that.devices1,
            })
          )
          .then((datas) => {
            console.log(datas.data.code);
            if (datas.data.code == 201) {
              that.$message.warning("暂无该设备信息");
            } else {
              that.search.ks_url = datas.data.data.ksUrl;
              that.search.kslite_url = datas.data.data.ksLiteUrl;
              that.search.webUrl = datas.data.data.webUrl;
              that.search.devices = datas.data.data.device;
            }
            that.lodings = false;
          });
      } else {
        this.$message.warning("请先输入设备编号");
      }
    },
    editor() {
      const that = this;

      if (this.ediors == "立即编辑") {
        this.ables = false;
        this.ediors = "立即保存";
      } else {
        this.ables = true;
        this.ediors = "立即编辑";
        that.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/editorDevicexcx",
            that.$qs.stringify(that.search)
          )
          .then((datas) => {
            this.$message.success("更新信息成功!");
            that.lodings = false;
          });
      }
    },
    // 生成web页面地址
    creats() {
      const that = this;

      if (this.devices2) {
        this.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/addqrcodes",
            that.$qs.stringify({
              deviceid: that.devices2,
            })
          )
          .then((datas) => {
            console.log(datas);
            that.webUrls = datas.data.data;
            that.lodings = false;
          });
      } else {
        this.$message.warning("请先输入设备编号");
      }
    },
    // 新增小程序地址
    adds() {
      const that = this;
      if (this.search2.devices) {
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/addDevicexcx",
            that.$qs.stringify(that.search2)
          )
          .then((datas) => {
            console.log(datas.data.code);
            if (datas.data.code == 201) {
              that.$message.success("更新小程序地址成功");
            } else {
              that.$message.success("新增小程序地址成功");
            }
            that.lodings = false;
            that.addsr = 1;
            that.devices1 = that.search2.devices;
            that.checks();
          });
      } else {
        this.$message.warning("请输入设备编号");
      }
    },
    downloadCode(device) {
      let content = this.$refs.content;
      let scrollHeight = content.scrollHeight;
      let scrollWidth = content.scrollWidth;
      html2canvas(content[0], {
        scale: window.devicePixelRatio,
        useCORS: true, //开启跨域配置，但和allowTaint不能共存
        width: scrollWidth,
        height: scrollHeight,
        windowWidth: scrollWidth,
        windowHeight: scrollHeight,
        x: 0,
        y: window.pageYOffset,
      }).then((canvas) => {
        this.operType = "edit";
        let dataURL = canvas.toDataURL("image/jpg");
        let link = document.createElement("a");
        link.href = dataURL;
        let filename = `${device}.jpg`; //文件名称
        link.setAttribute("download", filename);
        link.style.display = "none"; //a标签隐藏
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  watch: {
    "search2.devices"(newVal, oldVal) {
      this.addsr = "";
      this.search2.ks_url = "";
      this.search2.kslite_url = "";
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}
.left {
  width: 30%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.right {
  width: 68%;
  height: 100%;
  background-color: #fff;
  margin-left: 2%;
  display: flex;
}
.left_header {
  width: 100%;
  height: 60px;
  background-color: rgb(245, 245, 245);
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  /* white-space: nowrap; */
}
.left_header::-webkit-scrollbar {
  display: none !important;
}
.left_header > span {
  display: inline-block;
  padding: 0 10px;
  height: 40px;
  margin: 10px 5px;
  line-height: 40px;
  font-size: 13px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.left_contents {
  flex: 1;
  margin-top: 20px;
}
.el-form {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
}
.buttons {
  text-align: center;
  margin-top: 50px;
}
.checkalls {
  width: 100%;
  height: 100%;
}
.titles {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  font-weight: 550;
}
.seachers {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.show {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #bcbcbb;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.show > img {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}
.show > span {
  display: inline-block;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.qrcode {
  width: 300px !important;
  height: 300px !important
}
.qrcodess {
  width: 350px;
  height: 335px;
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 245, 245);
  padding-top: 15px;
  border-radius: 10px;
}
.qrcodess > div {
  width: 350px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcodess > p {
  width: 350px;
  text-align: center;
  font-size: 16px;
  margin-top: 7px;
}
</style>
