<template>
  <div
    class="contents"
    v-if="heights"
    :style="{ height: heights + 'px' }"
  ></div>
</template>
<script>
export default {
  data() {
    return {
      heights: "",
    };
  },
  mounted() {
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    // var imgs = this.getBase64Image("http://weixin.qq.com/q/02cpWX0XR3dmH10000g07H");
    // console.log(imgs)
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
    },

   getBase64Image(img) {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, img.width, img.height)
        var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
        var dataURL = canvas.toDataURL('image/' + ext)
        console.log(dataURL)
        return dataURL
      }
  },
  beforeRouteEnter(to,from,next){
      console.log(to)
    next()
  },
  beforeRouteLeave(to,from,next){
      console.log(to,from)
    next();
    // this.$confirm('您确定离开该项目吗？','提示',{
    //   confirmButtonText:'确定',
    //   cancelButtonText:'取消',
    //   type:'warning'
    // }).then(()=>{
    //   next();
    // }).catch(()=>{})

  },
  activated(){
    console.log('113131313')
  }
};
</script>

<style scoped>
.contents {
  background: url(../assets/img/index.png) no-repeat center;
  background-size: contain;
  background-color: #fff;
}
</style>
