import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/index.css'
import axios from 'axios'
import * as echarts from 'echarts'
import qs from 'qs'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$qs = qs
Vue.prototype.$http = axios


new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
}).$mount('#app')
