<template>
  <div class="contents">
    <div class="headers">
      <el-button type="primary" icon="el-icon-plus" @click="addweb"
        >新增日志</el-button
      >
    </div>
    <!-- 小程序列表 -->
    <div class="tables">
      <el-table
        :data="tableData"
        empty-text="暂无内容"
        style="width: 100%"
        max-height="750px"
        :stripe="true"
        :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
      >
        <el-table-column align="center" prop="webName" label="网址名称">
        </el-table-column>
        <el-table-column align="center" prop="classe" label="归属类" width="120px"  column-key="classe"
      :filters="[{text: '前端web', value: '前端web'}, {text: '小程序', value: '小程序'}, {text: 'PHP', value: 'PHP'}, {text: '前端组件/框架', value: '前端组件/框架'},{text: '公司', value: '公司'},{text: '服务器', value: '服务器'}]"
      :filter-method="filterHandler">
        </el-table-column>
        <el-table-column align="center" prop="address" label="网址" width="350px">
        </el-table-column>
        <el-table-column align="center" prop="descr" label="用途描述">
        </el-table-column>
        <el-table-column align="center" prop="creat_time" label="创建时间">
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          label="操作"
          width="260px"
        >
          <template slot-scope="scope">
            <el-button
              type="success"
              size="mini"
              @click="goin(scope.row.address)"
              >进入网址</el-button
            >
              <el-button
              type="primary"
              size="mini"
              @click="edit(scope.row)"
              >编辑</el-button
            >
             <el-button
              type="warning"
              size="mini"
              @click="delet(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增网址 -->
    <el-dialog title="新增网址" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="form" :label-width="formLabelWidth" :rules="formrule" ref="ruleForm">
        <el-form-item label="网址名称" prop="webName">
          <el-input v-model="form.webName" autocomplete="off"></el-input>
        </el-form-item>
       <el-form-item label="归属类" prop="classe">
          <el-input v-model="form.classe" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="网址" prop="address">
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用途描述" prop="descr">
          <el-input type="textarea" :rows="5" v-model="form.descr" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="reswebadd('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="addwebadd('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible:false,
      formLabelWidth:'120px',
      form:{
        webName:'',
        classe:'',
        address:'',
        descr:''
      },
      formrule:{
        webName:[
          { required: true, message: "请输入网站名称", trigger: "blur" },
        ],
        classe:[
          { required: true, message: "请输入归属类", trigger: "blur" },
        ],
        address:[
          { required: true, message: "请输入网站地址", trigger: "blur" },
        ],
        descr:[
          { required: true, message: "请输入网站作用和描述", trigger: "blur" },
        ]
      }
    };
  },
  mounted() {
    this.getNewdata();
  },
  methods: {
      getNewdata(){
          this.$http.get('https://wx.xiaoyuc.com/home/admin/getStudy').then(({data})=>{
            this.tableData = data.data
          })
      },
      // 新增学习网站
      addweb(){
          this.dialogFormVisible = true
      },
      // 进入学习网站
      goin(ids){
        window.open(ids);
        // this.$router.push({path:'/webview',query:{src:ids}})
      },
      // 编辑学习网站
      edit(dats){
        console.log(dats)
        this.form = dats;
        this.dialogFormVisible = true
      },
      // 删除学习网站
      delet(ids){
        const that = this
         this.$confirm('此操作将永久删除该网站记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           that.$http.post('https://wx.xiaoyuc.com/home/admin/deletStudy',that.$qs.stringify({
           id:ids
         })).then(({data})=>{
            if(data.code == 200){
                that.$message.success('删除成功!');
                that.getNewdata();
            }else{
                that.$message.error('删除失败！');
            }
          })
        }).catch(() => {
          that.$message({
            type: 'info',
            message: '已取消删除'
          });          
        })
      },
      // 取消新增
      reswebadd(form){
        this.$refs[form].resetFields();
        this.dialogFormVisible = false;
        this.getNewdata();
      },
      // 提交新增
      addwebadd(form){
        const that = this
        this.$refs[form].validate((val)=>{
           if(val){
             that.$http.post('https://wx.xiaoyuc.com/home/admin/addStudy',that.$qs.stringify(that.form)).then(({data})=>{
                console.log(data)
                if(data.code == 200){
                  that.$message.success(data.msg);
                  that.reswebadd(form)
                }
             })
           }
        })
      },
        filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
      }
  },
};
</script>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
}
.headers {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  line-height: 50px;
}
.headers > button {
  margin-left: 40px;
}
.contents_main {
  width: 100%;
  padding: 10px 0;
  background-color: aqua;
}
.el-table__body-wrapper{
  /* max-height: 690px !important; */
  overflow: auto;
}
.contents /deep/ .el-table__body-wrapper::-webkit-scrollbar{
  display: none !important;
}
.contents /deep/ .el-table__fixed-right-patch{
  display: none !important;
}
.contents /deep/ .el-table_4_column_20{
  cursor: pointer !important;
}
</style>