import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import main from '../components/main.vue'
import information from '../pages/information.vue'
import alignmentData from '../pages/alignmentData.vue'
import alignmentView from '../pages/alignmentView.vue'
import modelmanage from '../pages/modelmanage.vue'
import stylemanage from '../pages/stylemanage.vue'
import fixlog from '../pages/fixlog.vue'
import study from '../pages/student.vue'
import accounts from '../pages/accounts.vue'
import programOrder from '../pages/programOrder.vue'
import goods from '../pages/goods.vue'
import carrierOrder from '../pages/carrierOrder.vue'
import extendVideo from '../pages/extendVideo.vue'
import tiktokOrder from '../pages/tiktokOrder.vue'
import linkcodes from '../pages/linkcodes.vue'
import ksOrder from '../pages/ksOrder.vue'
import localgoods from '../pages/localgoods.vue'
import Posts from '../pages/Posts.vue'
import KSxcx from '../pages/KSxcx.vue'
import TiktokUsers from '../pages/TiktokUsers.vue'
import goodsOrders from '../pages/goodsOrders.vue'
import finshs from '../pages/finshs.vue'
import tiktokorders from '../pages/tiktokorders.vue'
import wtt from '../pages/wtt.vue'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path:'/',
    component:Login,
    meta:{'title':'登录'}
  },{
    path:'/main',
    component:main,
    redirect: '/information',
    children:[
      {
        path:'/information',   //数据中心
        component:information,
        meta:{'title':'数据中心'}
      },{
        path:'/alignmentData',   //新增小程序
        component:alignmentData,
        meta:{'title':'小程序管理'}
      },{
        path:'/alignmentView',    //小程序调整
        component:alignmentView,
        meta:{'title':'小程序调整'}
      },{
        path:'/modelmanage',      //模型管理
        component:modelmanage,
        meta:{'title':'小程序模型管理'}
      },{
        path:'/stylemanage',      //风格管理
        component:stylemanage,
        meta:{'title':'小程序风格管理'}
      },{
        path:'/fixlog',      //修复日志
        component:fixlog,
        meta:{'title':'修复日志'}
      },{
        path:'/study',     //学习模块
        component:study,
        meta:{'title':'学习模块'},
        beforeEnter:(to,from,next)=>{
          console.log(to,from)
          if(sessionStorage.getItem('id') == '15315956977'){
              next();
          }else {
             alert('您没有查看的权限')
          }
        }
      },{
        path:'/accounts',   //公众号订单模块
        component:accounts,
        meta:{'title':'公众号订单'}
      },{
        path:'/programOrder',  //小程序导流订单
        component:programOrder,
        meta:{'title':'小程序导流订单'}
      },
      {
        path:'/goods',     //拼团商品
        component:goods,
        meta:{'title':'拼团商品'}
      },
      {
        path:'/carrierOrder',  //服务商数据
        component:carrierOrder,
        meta:{'title':'代理商数据'}
      },{
        path:'/extendVideo',
        component:extendVideo,
        meta:{'title':'爆店视频管理'}
      },{
        path:'/tiktokOrder',
        component:tiktokOrder,
        meta:{'title':'抖音订单'}
      },{
        path:'/linkcodes',
        component:linkcodes,
        meta:{'title':'邀请口令'}
      },{
        path:'/ksOrder',
        component:ksOrder,
        meta:{'title':'快手订单'}
      },{
        path:'/localgoods',
        component:localgoods,
        meta:{'title':'本地团购订单'}
      },{
        path:'/Posts',
        component:Posts,
        meta:{'title':'接口管理'}
      },{
        path:'/KSxcx',
        component:KSxcx,
        meta:{'title':'快手小程序'}
      },{
        path:'/TiktokUsers',
        component:TiktokUsers,
        meta:{'title':'抖音用户管理'}
      },{
        path:'/goodsOrders',
        component:goodsOrders,
        meta:{'title':'拼团订单'}
      },{
        path:'/finshs',
        component:finshs,
        meta:{'title':'活动统计'}
      },{
        path:'/tiktokorders',
        component:tiktokorders,
        meta:{'title':'抖音订单'},

      },{
        path:'/wtt',
        component:wtt,
        meta:{'title':'wtt接口'}
      }
    ]
  }
]

 const  router = new VueRouter({
  mode: 'history',
  routes
})
//全局前置路由规则
router.beforeEach((to,from,next)=>{
  if(sessionStorage.getItem('id')|| to.path == '/'){
      next();
  }else{
    next({path:'/'})
  }
})
router.afterEach((to,from)=>{
  document.title = to.meta.title
  console.log(to,from)
})
export default router
