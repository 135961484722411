<template>
  <div class="contents">
    <div>
      <!-- 小程序列表 -->
      <div class="tables">
        <el-table
          :data="tableData"
          empty-text="暂无内容"
          style="width: 100%"
          v-if="heights"
          :height="heights"
          :stripe="true"
          :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
        >
         <el-table-column type="index" label="序号" align="center" width="80">
      </el-table-column>
          <el-table-column align="center" prop="dataName" label="小程序">
          </el-table-column>
          <el-table-column align="center" prop="appid" label="appid">
          </el-table-column>
          <el-table-column align="center" prop="packnum" label="打包次数">
          </el-table-column>
          <el-table-column
            align="center"
            prop="newpacktime"
            label="最新打包时间"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="newadjusttime"
            label="最新调整时间"
          >
          </el-table-column>
          <el-table-column align="center" prop="creat_time" label="创建时间">
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button
                type="warning"
                size="mini"
                @click="edit(scope.row.model, scope.row.appid)"
                >模型</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="banners(scope.row.appid)"
                >自定义banner</el-button
              >
              <el-button
                type="success"
                size="mini"
                @click="adjust(scope.row.appid)"
                >风格</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 小程序风格调整 -->
      <el-dialog
        title="小程序风格"
        width="25%"
        :visible.sync="adjustVisible"
        center
        :close-on-click-modal="false"
      >
        <div class="modelList">
          <div
            class="modelList_detail"
            v-for="(item, index) in adjustform"
            :key="index"
          >
            <el-image :src="item.imglist"></el-image>
            <div>
              <p>{{ item.stylename }}</p>
              <el-radio v-model="radio" :label="item.id">&nbsp;</el-radio>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="adjustReset('ruleForm')">取消</el-button>
          <el-button
            style="margin-left: 30px"
            type="primary"
            @click="adjustSubmit('ruleForm')"
            >立即更新</el-button
          >
        </div>
      </el-dialog>
      <!-- 模板调整窗口 -->
      <el-dialog
        title="小程序模板"
        width="35%"
        :visible.sync="adjustmodel"
        center
        :close-on-click-modal="false"
      >
        <div class="modelList">
          <div
            class="modelList_detail2"
            v-for="(item, index) in modelforms"
            :key="index"
          >
            <div class="detail_one">
              <el-image
                width="100%"
                height="100%"
                :src="item.modelimg"
              ></el-image>
            </div>
            <div class="detail_tow">
              <span>
                <p class="model_title">名称：</p>
                <p>{{ item.modelname }}</p>
              </span>
              <span>
                <p class="model_title">文件名称：</p>
                <p>{{ item.filename }}</p>
              </span>
              <span>
                <p class="model_title">创建时间：</p>
                <p>{{ item.creat_time }}</p>
              </span>
              <span>
                <p class="model_title">流量主：</p>
                <p v-if="item.is_flow == 0">无</p>
                <p v-else>有</p>
              </span>
              <span>
                <p class="model_title">作者：</p>
                <p>{{ item.modeldesigner }}</p>
              </span>
              <span>
                <p style="margin-right: 15px">当前的选择是：</p>
                <el-radio v-model="radios" :label="item.id">&nbsp;</el-radio>
              </span>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="adjustmodelreset('ruleForm')">取消</el-button>
          <el-button
            style="margin-left: 30px"
            type="primary"
            @click="adjustSubmitmodel('ruleForm')"
            >立即更新</el-button
          >
        </div>
      </el-dialog>
      <!-- banner上传窗口 -->
      <el-dialog
        title="banner上传"
        width="40%"
        :visible.sync="bannerVisible"
        center
        :close-on-click-modal="false"
      >
        <el-form
          :model="bannerform"
          :label-width="formLabelWidth"
          :rules="bannerrule"
          ref="ruleForm"
        >
          <el-form-item label="banner地址" prop="banner1">
            <el-input
              v-model="bannerform.banner1"
              placeholder="请输入banner地址，以英文逗号分割"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="是否使用默认">
            <el-radio-group v-model="bannerform.is_user">
              <el-radio :label="0">使用</el-radio>
              <el-radio :label="1">不使用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选取历史图片" v-if="bannerform.is_user == 1">
            <el-radio-group v-model="oldcheck">
              <el-radio :label="0">选取</el-radio>
              <el-radio :label="1">不选取</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="添加图片"
            v-if="bannerform.is_user == 1 && oldcheck == 1"
          >
            <el-upload
              action="#"
              :http-request="uploadProductPic"
              accept="image/*"
              list-type="picture-card"
              :limit="5"
              :on-preview="handleContImgPreview"
              :on-remove="handleContImgRemove"
              :on-exceed="exceedTips"
              ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogContImgVisible" append-to-body>
              <img width="100%" :src="dialogContImgUrl" />
            </el-dialog>
          </el-form-item>
          <el-form-item
            label="选择图片"
            v-if="bannerform.is_user == 1 && oldcheck == 0"
          >
            <div class="oldList">
                <el-checkbox-group v-model="checkList" @change="gostudy()">
                  <div
                    class="oldimglists"
                    v-for="(item, index) in bannerList"
                    :key="index"
                  >
                    <img :src="item.address" />
                    <span><el-checkbox :value="index" :label="item.imgname"></el-checkbox></span>
                  </div>
                </el-checkbox-group>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="bannerreset('ruleForm')">取消</el-button>
          <el-button
            style="margin-left: 30px"
            type="primary"
            @click="bannersubmit('ruleForm')"
            >立即更新</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      adjustform: [],
      stylenames: [],
      formLabelWidth: "120px",
      dialogLoading: false,
      adjustVisible: false,
      adjustLoading: false,
      radio: -1,
      adjustappid: "",
      adjustmodel: false,
      modelforms: [],
      radios: -1,
      appids: "",
      bannerVisible: false,
      bannerform: {
        banner1: "",
        appids: "",
        is_user: 0,
      },
      bannerrule: {
        banner1: [
          { required: true, message: "请选择banner图片", trigger: "blur" },
        ],
      },
      dialogContImgVisible: false,
      dialogContImgUrl: "",
      productPicPathList: [],
      ppclist: [],
      oldcheck: 0,
      checkList: [],
      bannerList:[],
      heights:''
    };
  },
  mounted() {
    this.getnewdata();
      const _this = this
      _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
     // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 140;
    },
    // 获取列表数据
    getnewdata() {
      const _that = this;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getList")
        .then(({ data }) => {
          for (var i = 0; i < data.data.length; i++) {
            data.data[i].creat_time = new Date(data.data[i].creat_time * 1000)
              .toLocaleString("chinese", { hour12: false })
              .replace(new RegExp("/", "g"), "-");
            if (
              data.data[i].newpacktime != null ||
              data.data[i].newpacktime != ""
            ) {
              data.data[i].newpacktime = new Date(
                data.data[i].newpacktime * 1000
              )
                .toLocaleString("chinese", { hour12: false })
                .replace(new RegExp("/", "g"), "-");
            }
            if (
              data.data[i].newadjusttime != null ||
              data.data[i].newadjusttime != ""
            ) {
              data.data[i].newadjusttime = new Date(
                data.data[i].newadjusttime * 1000
              )
                .toLocaleString("chinese", { hour12: false })
                .replace(new RegExp("/", "g"), "-");
            }
          }
          _that.tableData = data.data;
        });
    },
    // 模型调整
    edit(model, appids) {
      // this.$message.warning('开发中，敬请期待！');
      const that = this;
      that.radios = model * 1;
      that.appids = appids;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getmodelLsit")
        .then(({ data }) => {
          console.log(data);
          if (data.code == 200) {
            //成功
            that.modelforms = data.data;
          }
        });
      this.adjustmodel = true;
    },
    // 调整小程序风格
    adjust(index) {
      const _that = this;
      _that.adjustappid = index;
      this.adjustVisible = true;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getModeltop",
          _that.$qs.stringify({
            appid: index,
          })
        )
        .then(({ data }) => {
          console.log(data);
          _that.adjustform = data.data;
          // _that.stylenames = data.stylename;
          _that.radio = data.checked[0].type*1;
        });
    },

    // 风格窗口重置
    adjustReset() {
      this.radio = "";
      this.adjustappid = "";
      this.adjustLoading = false;
      this.adjustVisible = false;
    },
    // 风格窗口提交
    adjustSubmit() {
      var _this = this;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/adjustType",
          this.$qs.stringify({
            appid: this.adjustappid,
            type: this.radio,
          })
        )
        .then(({ data }) => {
          if (data.code == 200) {
            _this.$message.success("风格更新成功");
          } else {
            _this.$message.error("风格更新失败，请稍后再试！");
          }
          _this.adjustReset();
          _this.getnewdata();
        });
    },
    // 模板窗口重置
    adjustmodelreset() {
      this.adjustmodel = false;
    },
    // 模板窗口提交
    adjustSubmitmodel() {
      var _this = this;
      // 提交model和appid给接口，接口先查找模板文件名称，然后复制模板为对应小程序模板，复制成功之后，进行数据库更新！
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/matchmodels",
          this.$qs.stringify({
            appid: this.appids,
            model: this.radios,
          })
        )
        .then(({ data }) => {
          console.log(data.code);
          if (data.code == 200) {
            _this.$message.success("更新模板信息成功！");
            _this.adjustmodelreset();
            _this.getnewdata();
          } else {
            _this.$message.error("更新模板信息失败！");
            _this.adjustmodelreset();
          }
        });
    },
    // 调整banners
    banners(appids) {
      const that = this
      this.bannerform.appids = appids;
      this.bannerVisible = true;
       this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getAllbanner")
        .then(({ data }) => {
          that.bannerList = data.data
        });
    },
    uploadProductPic(file) {
      console.log(file);
      var fd = new FormData();
      fd.append("Img", file.file);
      this.uploadPic(fd);
      this.ppclist[this.ppclist.length] = file.file.name;
    },
    // 超出数量回调
    exceedTips() {
      this.$message({
        message: "当前最多可上传5张",
        type: "warning",
      });
    },
    // 删除事件
    handleContImgRemove: function (file, fileList) {
      const that = this;
      for (var j = 0; j < that.ppclist.length; j++) {
        if (file.name == that.ppclist[j]) {
          that.ppclist.splice(j, 1);
          that.productPicPathList.splice(j, 1);
          that.bannerform.banner1 = "";
          for (var i = 0; i < that.productPicPathList.length; i++) {
            if (i == that.productPicPathList.length * 1 - 1) {
              that.bannerform.banner1 += that.productPicPathList[i];
            } else {
              that.bannerform.banner1 += that.productPicPathList[i] + ",";
            }
          }
        }
      }
    },
    // 预览事件
    handleContImgPreview: function (file) {
      this.dialogContImgUrl = file.url;
      this.dialogContImgVisible = true;
    },
    // 上传图片
    uploadPic(data) {
      const that = this;
      var result = "";
      this.$http
        .post("https://wx.xiaoyuc.com/home/admin/getbanners", data)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            //成功
            result = res.data.imgname;
            var lengths = that.productPicPathList.length;
            that.productPicPathList[lengths] =
              "https://wx.xiaoyuc.com/static/imglist/banners/" + result;
            that.bannerform.banner1 = "";
            for (var i = 0; i < lengths + 1; i++) {
              if (i == lengths) {
                that.bannerform.banner1 += that.productPicPathList[i];
              } else {
                that.bannerform.banner1 += that.productPicPathList[i] + ",";
              }
            }
          }
        });
      return result;
    },
    // 取消banner上传
    bannerreset(forms) {
      this.bannerVisible = false,
      this.bannerform.banner1 = "";
       this.checkList = []
      this.$refs.upload.clearFiles();
     
    },
    // 提交banner
    bannersubmit(forms) {
      const _this = this;
      if (_this.bannerform.is_user == 1) {
        this.$refs[forms].validate((valid) => {
          console.log(valid);
          if (valid) {
            _this.$http
              .post(
                "https://wx.xiaoyuc.com/home/admin/getbannerlist",
                _this.$qs.stringify(_this.bannerform)
              )
              .then(({ data }) => {
                console.log(data);
                if (data.code == 201) {
                  _this.$message.success("新增banner成功！");
                } else {
                  _this.$message.success("更新banner成功！");
                }
                _this.bannerreset(forms);
              });
          }
        });
      } else {
        _this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/getbannerlist",
            _this.$qs.stringify(_this.bannerform)
          )
          .then(({ data }) => {
            console.log(data);
            if (data.code == 201) {
              _this.$message.success("使用默认成功");
            } else {
              _this.$message.success("更新默认成功");
            }
            _this.bannerreset(forms);
          });
      }
    },
    gostudy(){
      console.log(this.checkList)
     this.bannerform.banner1 = '';
      for (var i = 0; i < this.checkList.length;i++) {
          if (i == this.checkList.length-1) {
            this.bannerform.banner1 += "https://wx.xiaoyuc.com/static/imglist/banners/"+this.checkList[i];
          } else {
            this.bannerform.banner1 += "https://wx.xiaoyuc.com/static/imglist/banners/"+this.checkList[i] + ",";
          }
        }
    }
  },
};
</script>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
  overflow: hidden;
}
.contents > div {
  width: 98%;
  background-color: #fff;
  padding: 10px 1%;
}
.seachers {
  width: 98%;
  height: 60px;
  margin: auto;
}
.headers {
  width: 98%;
  height: 70px;
  background-color: #f2f2f2;
  margin: auto;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
}
.headers > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.tables {
  width: 98%;
  margin: auto;
  border: 1px solid #e7e7e7 !important;
  border-top: none !important;
}

.tables /deep/ .el-table__body-wrapper::-webkit-scrollbar{
  display: none !important;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modelList {
  width: 100%;
  max-height: 380px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.modelList::-webkit-scrollbar {
  display: none !important;
}
.modelList_detail {
  width: 49%;
  height: 180px;
  background-color: #f2f2f2;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.modelList_detail2 {
  width: 48%;
  height: 230px;
  background-color: #f2f2f2;
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  padding: 5px 0.5%;
}
.modelList_detail:nth-of-type(odd) {
  margin-right: 2%;
}
.modelList_detail2:nth-of-type(odd) {
  margin-right: 2%;
}
.modelList_detail > .el-carousel {
  width: 100%;
  height: 70%;
}
.modelList_detail > div:nth-of-type(2) {
  width: 80%;
  height: 30%;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
}
.modelList /deep/ .el-carousel__container {
  width: 100%;
  height: 100%;
}
.modelList_detail /deep/ .el-image__inner{
  height: 126px !important;
}
.detail_one {
  width: 40%;
  height: 100%;
}
.detail_tow {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}
.detail_tow > span {
  display: flex;
  flex: 1;
  height: 30px !important;
  line-height: 30px !important;
}
.detail_tow > span:last-child {
  height: 70px !important;
  line-height: 70px !important;
  align-items: center;
  justify-content: center;
}
.detail_one > .el-image {
  width: 100% !important;
  height: 100% !important;
}
.model_title {
  font-weight: 550;
  min-width: 50px;
  font-size: 15px;
}
.tips {
  width: 100%;
  text-align: center;
  color: red;
  margin-bottom: 10px !important;
}
.avatar-uploader {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
  border: 1px dashed #ccc;
}
.oldList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
}
.oldList::-webkit-scrollbar {
  display: none !important;
}
.el-checkbox-group{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
}
.el-checkbox-group::-webkit-scrollbar{
  display: none !important;
}
.oldimglists {
  width: 140px;
  height: 140px;
  background-color: #f4f4f4;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
}
.oldimglists > img {
  width: 100%;
  height: 115px;
}
.oldimglists > span{
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px !important;
}
 .oldList /deep/ .el-checkbox__label{
        font-size: 12px !important;
        padding-left: 3px !important;
    }
</style>