<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="header">
      <el-button
        type="success"
        icon="el-icon-refresh-right"
        @click="getreshaccounts"
        >刷新</el-button
      >
      <el-date-picker
        v-model="timese"
        @change="changetimes"
        value-format="yyyy-MM-dd"
        style="margin-left: 20px"
        type="date"
        default-value="2022-03-20"
        placeholder="选择日期"
      >
      </el-date-picker>
      <el-button
        class="downs"
        type="primary"
        icon="el-icon-download"
        @click="exportWageDetail"
        >导出表格</el-button
      >
    </div>
    <!-- 代理商订单 -->
    <el-table
      :data="tableData"
      v-if="heights2"
      empty-text="暂无内容"
      style="width: 100%"
      show-summary
      id="out-table"
      :stripe="true"
      :max-height="heights2"
      v-loading="tableDataLoading"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column type="index" label="序号" align="center" width="50">
      </el-table-column>
      <el-table-column align="center" width="160px" prop="data" label="日期">
      </el-table-column>
      <el-table-column
        align="center"
        width="180px"
        prop="account"
        label="代理商"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="200px"
        prop="appid"
        :filter-multiple="false"
        :filters="appname"
        :filter-method="filterHandler"
        :filtered-value="valuyrs"
        label="小程序"
      >
      </el-table-column>
      <el-table-column align="center" prop="flow_order" label="流量主订单">
      </el-table-column>
      <el-table-column align="center" prop="regorge_order" label="导流订单">
      </el-table-column>
      <el-table-column
        align="center"
        prop="sureRegorge_order"
        label="实际用户完成数"
      >
      </el-table-column>
      <el-table-column align="center" prop="account_order" label="公众号订单">
      </el-table-column>
      <el-table-column align="center" prop="flow_monery" label="流量主收益(元)">
      </el-table-column>
      <el-table-column
        align="center"
        prop="regorge_monery"
        label="导流收益(元)"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="account_monery"
        label="公众号收益(元)"
      >
      </el-table-column>
      <el-table-column align="center" prop="river_push" label="导流成本(元)">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX2 from "xlsx";
import XLSX from "xlsx-style";
export default {
  data() {
    return {
      heights: "",
      heights2: "",
      heights3: "",
      tableData: [],
      tableDataLoading: true,
      timese: "2022-03-23",
      appname: [],
      valuyrs:[]
    };
  },
  mounted() {
    var _this = this;
    _this.closetimes();
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getaccounts();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 200;
    },
    // 获取服务商订单
    getaccounts() {
      const that = this;
      that.tableDataLoading = true;
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getCarriers",
          that.$qs.stringify({
            times: that.timese,
          })
        )
        .then((datas) => {
          that.tableData = datas.data.data;
          that.totals = datas.data.totals;
          that.tableDataLoading = false;
          for (var i = 0; i < datas.data.appname.length; i++) {
            this.appname.push({
              text: datas.data.appname[i]["name"],
              value: datas.data.appname[i]["name"],
            });
          }
        });
    },
    getreshaccounts() {
      if (this.timese == null) {
        this.closetimes();
      }
      this.getaccounts();
    },
    // 日期选择
    changetimes(e) {
      if (e == null) {
        this.closetimes();
      }
      this.getaccounts();
    },
    // 时间重置
    closetimes() {
      this.addDate();
    },
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate() - 1,
      };
      const newmonth = date.month > 9 ? date.month : "0" + date.month;
      const day = date.date > 9 ? date.date : "0" + date.date;
      console.log(date.year + "-" + newmonth + "-" + day)
      this.timese = date.year + "-" + newmonth + "-" + day;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
      
    },
    exportWageDetail() {
      var $table = document.querySelector("#out-table");
      const wb = XLSX2.utils.table_to_sheet($table, {
        raw: true,
      });
      for (var i = 0; i < 11; i++) {
        wb["!cols"][i] = {
          wpx: 130,
        };
      }
      // 样式的文档地址
      // https://www.npmjs.com/package/xlsx-style
      for (const key in wb) {
        if (wb[key].v == 0 || (key.indexOf("!") === -1 && wb[key].v)) {
          let colors = "000000";
          let text = wb[key].v.toString();
          if (text.length > 4 && text.search("旷工") != -1) {
            console.log(text);
            colors = "FF0000";
          } else if (
            (text.length > 4 && text.search("出差") != -1) ||
            text.search("早退") != -1 ||
            text.search("迟到") != -1
          ) {
            console.log(text);
            colors = "ff7032";
          } else if (
            (text != "休息天数" &&
              text != "外出(小时)" &&
              text.search("外出") != -1) ||
            text.search("假") != -1 ||
            text.search("休") != -1
          ) {
            console.log(text);
            colors = "1460CB";
          }
          wb[key].s = {
            font: {
              //字体设置
              sz: 13,
              bold: false,
              color: {
                rgb: colors, //十六进制，不带#
              },
            },
            alignment: {
              //文字居中
              horizontal: "center",
              vertical: "center",
              wrap_text: true,
            },
            border: {
              // 设置边框
              top: {
                style: "thin",
              },
              bottom: {
                style: "thin",
              },
              left: {
                style: "thin",
              },
              right: {
                style: "thin",
              },
            },
          };
        }
      }
      console.log(wb["!merges"], wb);
      var data = this.addRangeBorder(wb["!merges"], wb); //合并项添加边框
      console.log(data);
      var filedata = this.sheet2blob(data);
      if(this.valuyrs.length >0){
        this.openDownloadDialog(filedata, this.timese+this.valuyrs[0]+"收益.xlsx");
      }else{
         this.openDownloadDialog(filedata, this.timese+"全部收益.xlsx");
      }
    },
    //为合并项添加边框
    addRangeBorder(range, ws) {
      let arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      if (range) {
        range.forEach((item) => {
          let startColNumber = Number(item.s.r),
            endColNumber = Number(item.e.r);
          let startRowNumber = Number(item.s.c),
            endRowNumber = Number(item.e.c);
          const test = ws[arr[startRowNumber] + (startColNumber + 1)];
          for (let col = startColNumber; col <= endColNumber; col++) {
            for (let row = startRowNumber; row <= endRowNumber; row++) {
              ws[arr[row] + (col + 1)] = test;
            }
          }
        });
      }
      return ws;
    },
    //将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
    sheet2blob(sheet, sheetName) {
      sheetName = sheetName || "sheet1";
      var workbook = {
        SheetNames: [sheetName],
        Sheets: {},
      };
      workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

      var wopts = {
        bookType: "xlsx", // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: "binary",
      };
      var wbout = XLSX.write(workbook, wopts);
      var blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      }); // 字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      return blob;
    },
    openDownloadDialog(url, saveName) {
      if (typeof url == "object" && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
      }
      var aLink = document.createElement("a");
      aLink.href = url;
      aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
      var event;
      if (window.MouseEvent) event = new MouseEvent("click");
      else {
        event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
      }
      aLink.dispatchEvent(event);
    },
    changefilters(columnKey){
      console.log(columnKey)
    }
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.header button {
  margin-left: 20px;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recordse /deep/ .el-dialog {
  margin-top: 7vh !important;
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  background-color: #fff;
  padding-bottom: 20px;
}
.recordse /deep/ .el-dialog__body {
  padding-bottom: 10px !important;
}
.downs {
  position: absolute;
  right: 50px;
  font-size: 12px !important;
}
</style>