<template>
  <div class="contents">
    <div class="headers">
      <el-button type="primary" icon="el-icon-plus" @click="addstyle(1)"
        >添加风格</el-button
      >
    </div>
    <div v-if="forms.length > 0" :style="{ height: heights + 'px' }">
      <div class="model_list" v-for="(item, index) in forms" :key="index">
        <div class="imglistyulan">
          <el-image fit="cover" :src="item.imglist"></el-image>
        </div>
        <div class="modeldec">
          <div> <span>
            <p class="model_title">名称：</p>
            <p>{{ item.stylename }}</p>
          </span>
           <span>
            <p class="model_title">文件名称：</p>
            <p>{{ item.stylefile }}</p>
          </span>
          <span>
            <p class="model_title">创建时间：</p>
            <p>{{ item.updata_time }}</p>
          </span>
          <span>
            <p class="model_title">作者：</p>
            <p>{{ item.desirname }}</p>
          </span>
        </div>
          <div>
                 <span>
            <p>
              <el-button
                type="primary"
                round
                size="mini"
                @click="editstyle(item)"
                >编辑</el-button
              >
            </p>
             <p>
              <el-button
                type="primary"
                round
                size="mini"
                @click="upstyles(item.id)"
                >上传文件</el-button
              >
            </p>
            <p>
              <el-button
                type="success"
                round
                size="mini"
                @click="matchadd(item.id,item.stylefile)"
                >匹配地址</el-button
              >
            </p>
          </span>
          </div>
     
        </div>
        <!-- 上传风格文件 -->
        <el-dialog
          title="请上传风格文件"
          width="25%"
          :visible.sync="dialogstyle"
          center
          :close-on-click-modal="false"
        >
          <el-form v-loading="styleloading" enctype="multipart/form-data">
            <el-form-item prop="imglist">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :auto-upload="true"
                name="uploads"
                :http-request="getstylefiles"
              >
                <el-image
                  fit="cover"
                  v-if="fileurls"
                  :src="fileurls"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  margin-top: 20px;
                  font-weight: 550;
                  color: red;
                "
                >注意：仅支持压缩包文件！！！</span
              >
              <span
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  font-weight: 550;
                  color: red;
                "
                >注意：文件名要和模板文件名称一致！</span
              >
              <span
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  font-weight: 550;
                  color: red;
                "
                >注意：文件名在上面的基础上后面加数字，区分哪种风格！</span
              >
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="styleforms()">取消</el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="upstyleforms()"
              >立即上传</el-button
            >
          </div>
        </el-dialog>
      </div>
      <!-- 新增风格窗口 -->
      <el-dialog
        title="新增风格"
        :visible.sync="addstyledialog"
        width="25%"
        center
        :close-on-click-modal="false"
      >
        <el-form :model="addstylforms" 
            :rules="stylerules"
            ref="ruleForm"
            :label-width="formLabelWidth"
            v-loading="styleloading"
            enctype="multipart/form-data"
        >
          <el-form-item label="风格名称" :label-width="formLabelWidth" prop="names">
            <el-input
              v-model="addstylforms.names"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定模型" :label-width="formLabelWidth" prop="model">
            <el-select v-model="addstylforms.model" multiple  placeholder="请选择模型">
              <el-option
                v-for="item in models"
                :key="item.id"
                :label="item.modelname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作者" :label-width="formLabelWidth" prop="desirname">
            <el-input
              v-model="addstylforms.desirname"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="风格预览图" :label-width="formLabelWidth" prop="imglist">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :auto-upload="true"
              name="uploads"
              :http-request="ImgUploadSectionFile"
            >
              <el-image
                fit="cover"
                v-if="styleUrl"
                :src="styleUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="stylereset('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitstyl('ruleForm')"
            >{{buttons}}</el-button
          >
        </div>
      </el-dialog>
       <!-- 匹配地址窗口 -->
      <el-dialog
        title="匹配地址"
        :visible.sync="matchadddiao"
        width="25%"
        center
        :close-on-click-modal="false"
      >
        <el-form :model="matchform" 
        :rules="matchrules"
            ref="ruleForm"
            label-width="200px"
            v-loading="styleloading"
            enctype="multipart/form-data"
        >
          <el-form-item label="任务页色号1(深色号)"  prop="task_color1">
            <el-input
              v-model="matchform.task_color1"
              autocomplete="off"
              placeholder="请输入任务页色号(深色号)"
            >
               
            </el-input>
          </el-form-item>
          <el-form-item label="任务页色号2(浅色号)"  prop="task_color2">
                 <el-input
              v-model="matchform.task_color2"
              autocomplete="off"
              placeholder="请输入任务页色号(浅色号)"
            >
               
            </el-input>
          </el-form-item>
          <el-form-item label="购买页色号(深色好)"  prop="pay_color2">
             <el-input
              v-model="matchform.pay_color2"
              autocomplete="off"
              placeholder="请输入购买页色号(深色好)"
            >  
            </el-input>
          </el-form-item>
           <el-form-item label="购买页色号(浅色号)"  prop="pay_color1">
             <el-input
              v-model="matchform.pay_color1"
              autocomplete="off"
              placeholder="请输入购买页色号(浅色号)"
            >
            </el-input>
           </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resaddress('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitaddress('ruleForm')"
            >立即添加</el-button
          >
        </div>
      </el-dialog>
    </div>
    <el-empty v-else :style="{ height: imgheights + 'px' }" :image-size="200">
     <!-- 新增风格窗口 -->
      <!-- <el-dialog
        title="新增风格"
        :visible.sync="addstyledialog"
        width="25%"
        center
      >
        <el-form :model="addstylforms" 
            :rules="stylerules"
            ref="ruleForm"
            :label-width="formLabelWidth"
            v-loading="styleloading"
            enctype="multipart/form-data"
        >
          <el-form-item label="风格名称" :label-width="formLabelWidth" prop="names">
            <el-input
              v-model="addstylforms.names"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定模型" :label-width="formLabelWidth" prop="model">
            <el-select v-model="addstylforms.model" multiple  placeholder="请选择模型">
              <el-option
                v-for="item in models"
                :key="item.id"
                :label="item.modelname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作者" :label-width="formLabelWidth" prop="desirname">
            <el-input
              v-model="addstylforms.desirname"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="风格预览图" :label-width="formLabelWidth" prop="imglist">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :auto-upload="true"
              name="uploads"
              :http-request="ImgUploadSectionFile"
            >
              <el-image
                fit="cover"
                v-if="styleUrl"
                :src="styleUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="stylereset('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitstyl('ruleForm')"
            >132132</el-button
          >
        </div>
      </el-dialog> -->
    </el-empty>
  </div>
</template>


<script>
export default {
  data() {
    return {
      heights: "",
      forms: [],
      formLabelWidth: "120px",
      imageUrl: "", //展示的url地址
      imgheights: "", //无数据模块的高度
      fileurls: "", //风格文件添加成功反馈的图片
      dialogstyle: false, //上传风格文件窗口
      styleloading: false, //风格文件上传加载动画
      stylefiles: "", //存储需要上传的风格文件
      model: "", //上传文件带着上传的model
      // 添加风格部分
      addstyledialog: false,
      addstylforms: {
        names: "",
        model:[],
        desirname: "",
        imglist: "",
        styleid:''
      },
      styleUrl: "",
      models:[],
      filestyle:"",
      stylerules:{
          names: [
          { required: true, message: "请输入风格名称", trigger: "blur" },
        ],
          model: [
          { required: true, message: "请绑定模板", trigger: "blur" },
        ],
          desirname: [
          { required: true, message: "请输入作者名称", trigger: "blur" },
        ],
          imglist: [
          { required: true, message: "请上传预览图", trigger: "blur" },
        ],
      },
      // 匹配地址部分
      matchadddiao:false,
      matchform:{
          task_color1:'',
          task_color2:'',
          pay_color1:'',
          pay_color2:'',
          type:'',
          imgname:''
      },
       matchrules:{
          task_color1: [
          { required: true, message: "请输入任务页深色号", trigger: "blur" },
        ],
         task_color2: [
          { required: true, message: "请输入任务页浅色好", trigger: "blur" },
        ],
         pay_color1: [
          { required: true, message: "请输入购买页浅色号", trigger: "blur" },
        ],
         pay_color2: [
          { required: true, message: "请输入购买页深色号", trigger: "blur" },
        ],
      },
      styleid:'',
      buttons:''
    };
  },
  mounted() {
    var _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getmodelList();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      var height1 = Math.ceil((this.forms.length * 1) / 4) * 320;
      console.log(height1);
      this.heights = document.body.clientHeight * 1 - 150;
      this.imgheights = document.body.clientHeight * 1 - 250;
      if (height1 > this.heights) {
      } else {
        this.heights = height1;
      }
    },
    // 新增风格窗口
    addstyle(num) {
      const that = this;
      if(num == 2){
         that.buttons = '立即更新'
      }else{
         that.buttons = '立即添加'
      }
      this.addstyledialog = true;
       this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getmodelLsit")
        .then(({ data }) => {
          if (data.code == 200) {
            that.models = data.data;
          }
        });
    },
    // 获取列表数据
    getmodelList() {
      const that = this;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getstylelist")
        .then(({ data }) => {
          if (data.code == 200) {
            //成功
            console.log(data)
            that.forms = data.data;
            that.resizeHeight();
          }
        });
    },
    // 获取风格预览图
    ImgUploadSectionFile(param) {
      console.log(param);
      this.filestyle = param;
      this.styleUrl = URL.createObjectURL(param.file);
    },
    // 取消添加风格
    stylereset(form){
        this.$refs[form].resetFields();
      this.styleloading=false
        this.styleUrl = '';
        this.filestyle = ''
        this.addstyledialog = false
    },
    // 提交风格添加
    submitstyl(form){
      var _this = this
      this.styleloading=true
      console.log(this.addstylforms)
      if(_this.filestyle == ''){
           _this.$refs[form].validate((valid) => {
              if (valid) {
                _this.addstylforms.id = _this.styleid
                _this.$http
                  .post(
                    "https://wx.xiaoyuc.com/home/admin/creatstyle",
                    _this.$qs.stringify(_this.addstylforms)
                  )
                  .then(({ data }) => {
                    if (data.code == 200) {
                      _this.$message.success("新增风格成功！");
                    } else {
                      _this.$message.error("新增风格失败！");
                    }
                    _this.stylereset(form);
                    _this.getmodelList();
                  });
              } else {
                return false;
              }
            });
      }else{
      let formData = new FormData(); //formdata格式
      formData.append("Img", _this.filestyle.file);
      this.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/getimglist",
          formData
        )
        .then((res) => {
             if (res.data.code == 200) {
            this.addstylforms.imglist = res.data.imgname;
            _this.$refs[form].validate((valid) => {
              if (valid) {
                _this.$http
                  .post(
                    "https://wx.xiaoyuc.com/home/admin/creatstyle",
                    _this.$qs.stringify(_this.addstylforms)
                  )
                  .then(({ data }) => {
                    if (data.code == 200) {
                      _this.$message.success("新增风格成功！");
                    } else {
                      _this.$message.error("新增风格失败！");
                    }
                    _this.stylereset(form);
                    _this.getmodelList();
                  });
              } else {
                return false;
              }
            });
          } else {
            _this.$message.error("预览图上传失败，请重新尝试！");
          }
        });
      }
    },
    // 编辑风格
    editstyle(items){
      console.log(items)
      // return;
      this.buttons = '立即更新'
      this.addstylforms.names = items.stylename
      this.addstylforms.model = items.model
      this.addstylforms.desirname = items.desirname
      this.addstylforms.imglist = items.imglist
      this.styleUrl = items.imglist
      this.addstylforms.styleid = items.id
      console.log(this.addstylforms.model)
      this.addstyle(2)
    },
    // 匹配地址
    matchadd(id,filename) {
        this.matchadddiao = true
        this.matchform.type = id
        this.matchform.imgname = filename
    },
    // 取消地址匹配
    resaddress(form){
        this.$refs[form].resetFields();
        this.matchform.type = '';
        this.matchform.imgname = '';
        this.matchadddiao = false
    },
    // 提交地址匹配
    submitaddress(form){
     var _this = this
     _this.$refs[form].validate((valid) => {
       if(valid){
          _this.$http.post('https://wx.xiaoyuc.com/home/admin/matchaddress',_this.$qs.stringify(_this.matchform)).then(({data})=>{
          console.log(data)
          if(data.code == 200){
              _this.resaddress(form)
              _this.$message.success('匹配图片地址成功！')
          }
        })
       }
     })
    },
    // 上传风格文件
    upstyles(id) {
      this.dialogstyle = true;
      this.model = id;
    },
    // 选中风格文件
    getstylefiles(param) {
      console.log(param);
      this.stylefiles = param.file;
      this.fileurls =
        "https://wx.xiaoyuc.com/static/wechats/models/success.png";
    },
    // 取消上传风格文件
    styleforms() {
      this.fileurls = "";
      this.stylefiles = "";
      this.model = "";
      this.dialogstyle = false;
    },
    // 提交风格文件
    upstyleforms() {
      const that = this;
      if (this.stylefiles == "") {
        this.$message.error("文件已失效，请重新上传文件！");
      } else {
        that.styleloading = true;
        let formData = new FormData(); //formdata格式
        formData.append("Img", this.stylefiles);
        formData.append("model", this.model);
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/getstylefile",
            formData
          )
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              //成功
              console.log(res);
              setTimeout(() => {
                that.styleloading = false;
                that.styleforms();
                that.$message.success("上传模板文件成功！");
              }, 2000);
            }
          });
      }
    },
  },
};
</script>


<style scoped>
.contents {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
}
.contents > div {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.contents > div::-webkit-scrollbar {
  display: none !important;
}
.model_list {
  width: 23%;
  height: 300px;
  background-color: #fff;
  margin-right: 1%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px 0.5%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 1px 1px #e6e6e6;
}
.model_list:last-child {
  margin-bottom: 10px;
}
.model_list > div {
  flex: 1;
}
.el-image {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.modeldec {
  display: flex;
}
.modeldec>div:nth-of-type(1){
  width: 65%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modeldec>div:nth-of-type(2){
  width: 35%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.imglistyulan{
  width: 100%;
  height: 180px !important;
}
.imglistyulan /deep/ .el-image__inner{
  height: 180px !important;
}
.modeldec>div:nth-of-type(1) > span {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
}
.modeldec>div:nth-of-type(2) > span {
 flex-direction: column;
 display: flex;
 height: 120px;
}
.modeldec>div:nth-of-type(2) > span >p{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modeldec > span > p:nth-of-type(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.modeldec > span:nth-of-type(5) {
  height: 115px !important;
  margin-top: 5px;
}
.modeldec > span:nth-of-type(5) > p:nth-of-type(1) {
  min-width: 50px;
}
.modeldec > span:nth-of-type(5) > p:nth-of-type(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 115px;
  line-height: 20px;
  font-size: 14px;
}
.modeldec > span:nth-of-type(6) {
  height: 60px !important;
  display: flex;
}
.model_title {
  font-weight: 550;
  min-width: 50px;
  font-size: 15px;
}
p {
  margin: 0% !important;
}
.modeldec > span:nth-of-type(6) > p {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headers {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.headers > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.contents /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.imglistshows /deep/ .el-dialog {
  margin-top: 100px !important;
  background: none !important;
  box-shadow: none !important;
}
.imglistshows /deep/ .el-dialog__close {
  color: #fff !important;
  font-size: 25px !important;
}
.tips{
  width: 100%;
  text-align: center;
  color: red;
  margin-bottom: 10px !important;
}
</style>