<template>
  <div class="contents">
    <el-container>
      <el-header>
        <p>小程序配置平台</p>
        <div>
          尊敬的 {{ username }} 您好！
          <span @click="quit"
            ><el-avatar> {{ username }} </el-avatar></span
          >
        </div>
      </el-header>
      <el-container>
        <el-aside width="230px" :style="{ height: heights }">
          <el-menu
            default-active="/information"
            class="el-menu-vertical-demo"
            router
            collapse-transition
          >
            <el-menu-item index="/information">
              <i class="el-icon-pie-chart"></i>
              <span slot="title">数据中心</span>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-mobile"></i>
                <span>小程序专区</span>
              </template>
              <el-menu-item index="/alignmentData">
                <i class="el-icon-data-line"></i>
                <span slot="title">新增小程序</span>
              </el-menu-item>
              <el-menu-item index="/alignmentView">
                <i class="el-icon-view"></i>
                <span slot="title">界面调整</span>
              </el-menu-item>
              <el-menu-item index="/fixlog">
                <i class="el-icon-view"></i>
                <span slot="title">bug修复日志</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-mobile"></i>
                <span>界面风格专区</span>
              </template>
              <el-menu-item index="/modelmanage">
                <i class="el-icon-data-line"></i>
                <span slot="title">模型管理</span>
              </el-menu-item>
              <el-menu-item index="/stylemanage">
                <i class="el-icon-view"></i>
                <span slot="title">风格管理</span>
              </el-menu-item>
            </el-submenu>
            <!-- <el-menu-item index="/carrierOrder">
              <i class="el-icon-pie-chart"></i>
              <span slot="title">代理商数据</span>
            </el-menu-item> -->
            <!-- <el-menu-item index="/Posts">
              <i class="el-icon-pie-chart"></i>
              <span slot="title">接口专区</span>
            </el-menu-item> -->
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-mobile"></i>
                <span>订单专区</span>
              </template>
              <el-menu-item index="/accounts">
                <i class="el-icon-tickets"></i>
                <span slot="title">公众号订单</span>
              </el-menu-item>
              <el-menu-item index="/programOrder">
                <i class="el-icon-document"></i>
                <span slot="title">小程序订单</span>
              </el-menu-item>
              <!-- <el-menu-item index="/tiktokOrder">
                <i class="el-icon-document-checked"></i>
                <span slot="title">抖音号订单</span>
              </el-menu-item> -->
              <el-menu-item index="/tiktokorders">
                <i class="el-icon-document-checked"></i>
                <span slot="title">抖音团购电商订单</span>
              </el-menu-item>
              <el-menu-item index="/ksOrder">
                <i class="el-icon-document-checked"></i>
                <span slot="title">快手号订单</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <i class="el-icon-mobile"></i>
                <span>抖音专区</span>
              </template>
              <el-menu-item index="/extendVideo">
                <i class="el-icon-data-line"></i>
                <span slot="title">爆店小视频</span>
              </el-menu-item>
              <el-menu-item index="/linkcodes">
                <i class="el-icon-link"></i>
                <span slot="title">邀请口令</span>
              </el-menu-item>
              <el-menu-item index="/localgoods">
                <i class="el-icon-link"></i>
                <span slot="title">本地好店</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">
                <i class="el-icon-mobile"></i>
                <span>积分专区</span>
              </template>
              <el-menu-item index="/goods">
                <i class="el-icon-shopping-cart-full"></i>
                <span slot="title">积分商品</span>
              </el-menu-item>
              <el-menu-item index="/goodsOrders">
                <i class="el-icon-shopping-cart-full"></i>
                <span slot="title">自营订单</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">
                <i class="el-icon-notebook-2"></i>
                <span>学习模块</span>
              </template>
              <el-menu-item index="/study">
                <i class="el-icon-collection-tag"></i>
                <span slot="title">网站库</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="8">
              <template slot="title">
                <i class="el-icon-notebook-2"></i>
                <span>接口专区</span>
              </template>
              <el-menu-item index="/KSxcx">
                <i class="el-icon-collection-tag"></i>
                <span slot="title">快手接口</span>
              </el-menu-item>
              <el-menu-item index="/TiktokUsers">
                <i class="el-icon-collection-tag"></i>
                <span slot="title">抖音接口</span>
              </el-menu-item>
              <el-menu-item index="/wtt">
                <i class="el-icon-collection-tag"></i>
                <span slot="title">批量接口</span>
              </el-menu-item>
            </el-submenu>
             <el-submenu index="9">
              <template slot="title">
                <i class="el-icon-mobile"></i>
                <span>活动专区</span>
              </template>
              <el-menu-item index="/finshs">
                <i class="el-icon-shopping-cart-full"></i>
                <span slot="title">鸡蛋完成订单</span>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <!-- <div class="tips"></div> -->
          <router-view></router-view>
          <a class="bottoms" href="https://beian.miit.gov.cn/" target="_blank">copyright © 2022 鲁ICP备2022000176号-3</a>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: "测试用户",
      ids: "",
      heights: "",
    };
  },
  mounted() {
    var _this = this;
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.ids = window.sessionStorage.getItem("id");
    _this.username = window.sessionStorage.getItem("username");
    if (_this.ids == "" || _this.ids == null) {
      setTimeout(() => {
        _this.$router.replace("/");
      }, 100);
    } else {
      this.$router.push("/information");
    }
    _this.resizeHeight();
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 60 + "px";
    },
    quit() {
      const _this = this;
      this.$confirm("您确定需要退出小程序配置平台吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        window.sessionStorage.removeItem("id");
        window.sessionStorage.removeItem("username");
        _this.$router.replace("/");
      });
    },
  },
};
</script>

<style scoped>
.contents {
  background-color: #f5f7f9;
  display: flex;
}
.el-header {
  display: flex;
  background-color: #fff;
  align-items: center;
  position: relative;
  padding-left: 0% !important;
}
.el-aside {
  border-right: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px #e3e3e3;
}
.el-aside::-webkit-scrollbar {
  display: none !important;
}
.el-header > p {
  font-size: 20px;
  font-weight: 550;
  width: 230px;
  text-align: center;
}
.el-header > div {
  position: absolute;
  top: 0%;
  right: 20px;
  height: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.el-main {
  display: flex;
  flex-direction: column;
}
/* .tips {
  width: 100%;
  height: 50px;
} */
.el-menu {
  border-right: none !important;
}
.bottoms {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  font-size: 14px;
  margin: 0% !important;
}
</style>
