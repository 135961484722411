<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="header">
      <el-button type="primary" icon="el-icon-plus" @click="addprogram"
        >新增订单</el-button
      >
      <el-button
        type="success"
        icon="el-icon-refresh-right"
        @click="getaccounts"
        >刷新</el-button
      >
    </div>
    <!-- 公众号订单 -->
    <el-table
      :data="tableData"
      v-if="heights"
      empty-text="暂无内容"
      style="width: 100%"
      :max-height="heights2"
      v-loading="tableDataLoading"
      :header-cell-style="{ background: '#F2F2F2', color: '#606266' }"
    >
      <el-table-column
        fixed
        align="center"
        width="100px"
        prop="id"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column align="center" prop="accountname" label="公众号名称">
      </el-table-column>
      <el-table-column align="center" width="100px" prop="code" label="兑换码">
      </el-table-column>
      <el-table-column
        align="center"
        prop="count"
        label="计划关注"
        width="100px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="100px"
        prop="alercount"
        label="完成关注"
      >
      </el-table-column>
      <el-table-column align="center" prop="phone" label="联系方式">
      </el-table-column>
      <el-table-column align="center" prop="prior" label="优先级">
      </el-table-column>
      <el-table-column align="center" width="100px" prop="monery" label="金额">
      </el-table-column>
      <el-table-column align="center" width="100px" prop="status" label="状态">
      </el-table-column>
      <el-table-column align="center" prop="accountappids" label="关联公众号">
      </el-table-column>
      <el-table-column
        align="center"
        width="160px"
        prop="creat_time"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="350px">
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="priority(scope.row.id, scope.row.prior)"
            >优先级</el-button
          >
          <el-button type="success" size="mini" @click="getOrderDetails(scope.row.id)"
            >订单数据</el-button
          >
          <el-button
            type="default"
            size="mini"
            v-clipboard:copy="scope.row.usersrc"
            v-clipboard:success="onCopy"
            >复制链接</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="statuse(scope.row.id, scope.row.status)"
            >状态</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 优先级调整窗口 -->
    <el-dialog
      title="优先级调整"
      :visible.sync="priorvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="priors">
        <el-radio :label="1">1级优先</el-radio>
        <el-radio :label="2">2级优先</el-radio>
        <el-radio :label="3">3级优先</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(1)" type="danger">取消</el-button>
        <el-button @click="sures(1)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 关联公众号调整窗口 -->
    <el-dialog
      title="关联公众号调整"
      :visible.sync="accountappidsvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-select v-model="accountappids" placeholder="请选择">
        <el-option
          v-for="item in appidlist"
          :key="item.appid"
          :label="item.name"
          :value="item.appid"
        >
        </el-option>
      </el-select>
      <div class="buttons">
        <el-button @click="calels(2)" type="danger">取消</el-button>
        <el-button @click="sures(2)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 状态调整窗口 -->
    <el-dialog
      title="优先级调整"
      :visible.sync="statusvisible"
      width="20%"
      center
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="status">
        <el-radio :label="0">进行中</el-radio>
        <el-radio :label="2">已完成</el-radio>
        <el-radio :label="1">暂停</el-radio>
      </el-radio-group>
      <div class="buttons">
        <el-button @click="calels(3)" type="danger">取消</el-button>
        <el-button @click="sures(3)" type="success">确认</el-button>
      </div>
    </el-dialog>
    <!-- 新增公众号订单 -->
    <el-dialog
      title="新增订单"
      :visible.sync="accountOrder"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="addform"
        :rules="addrules"
        ref="ruleForm"
        label-width="130px"
      >
        <el-form-item label="公众号名称" prop="accountname">
          <el-input
            v-model="addform.accountname"
            autocomplete="off"
            placeholder="请输入需要推广的公众号名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="吸粉数量" prop="count">
          <el-input
            v-model="addform.count"
            autocomplete="off"
            placeholder="请输入需要推广的数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="公众号二维码" prop="imglist">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :auto-upload="true"
            name="uploads"
            :http-request="ImgUploadSectionFile"
          >
            <el-image
              fit="cover"
              v-if="styleUrl"
              :src="styleUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="submitForm('ruleForm')"
          >立即新增</el-button
        >
      </div>
    </el-dialog>
    <input type="text" id="str" :value="copses" style="opacity: 0" />
    <div :height="heights2" class="plocs" v-if="orderShows == true">
        <accountDetails :id="this.orderids" @hidemys="hidemys"></accountDetails>
    </div>
  </div>
</template>

<script>
import accountDetails from './accountDetails.vue'
import pubsub from 'pubsub-js'
export default {
  components:{accountDetails},
  data() {
    return {
      heights: "",
      heights2: "",
      tableData: [],
      priorvisible: false,
      priors: 3,
      ids: "",
      status: "",
      statusvisible: false,
      accountappids: "",
      accountappidsvisible: false,
      appidlist: [],
      tableDataLoading: true,
      accountOrder: false,
      styleUrl: "",
      filestyle: "",
      addform: {
        accountname: "",
        count: "",
        imglist: "",
      },
      addrules: {
        accountname: [
          { required: true, message: "请输入公众号名称", trigger: "blur" },
        ],
        count: [
          { required: true, message: "请输入需要推广的数量", trigger: "blur" },
        ],
        imglist: [
          { required: true, message: "请输入上传二维码", trigger: "blur" },
        ],
      },
      copses: "",
      orderids:'',
      orderShows:false,
      pubId:''
    };
  },
  mounted() {
    var _this = this;
    this.pubId = pubsub.subscribe('hello',function(msgName,data){
            console.log('---------------------',data,msgName)
    })
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
    _this.getaccounts();
    this.$on('hidemys',(datas)=>{
      console.log(datas)
    })
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
    },
    // 获取公众号订单
    getaccounts() {
      const that = this;
      that.tableDataLoading = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/getaccountorders")
        .then((datas) => {
          that.tableData = datas.data.data;
          that.tableDataLoading = false;
          console.log(that.tableData);
        });
    },
    // 获取关联公众号列表
    getsendaccounts() {
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/sendaccounts")
        .then((datas) => {
          this.appidlist = datas.data.data;
        });
    },
    // 调整优先级
    priority(id, priors) {
      this.priors = priors * 1;
      this.ids = id;
      this.priorvisible = true;
    },
    // 调整关联的公众号网页
    relevance(id) {
      this.getsendaccounts();
      this.ids = id;
      this.accountappidsvisible = true;
    },
    // 调整当前状态
    statuse(id, status) {
      if (status == "已完成") {
        this.status = 2;
      } else if(status == '进行中') {
        this.status = 0;
      }else{
        this.status = 1;
      }
      this.ids = id;
      this.statusvisible = true;
    },
    // 取消显示窗口
    calels(num) {
      if (num == 1) {
        this.priorvisible = false;
        this.priors = "";
      } else if (num == 2) {
        this.accountappidsvisible = false;
        this.accountappids = "";
      } else {
        this.statusvisible = false;
        this.status = "";
      }
    },
    // 确认显示窗口
    sures(num) {
      const that = this;
      if (num == 1) {
        that.status = "";
        that.accountappids = "";
      } else if (num == 2) {
        that.status = "";
        that.priors = "";
      } else {
        that.accountappids = "";
        that.priors = "";
      }
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/admin/editaccountorders",
          that.$qs.stringify({
            accountappids: that.accountappids,
            status: that.status,
            prior: that.priors,
            id: that.ids,
          })
        )
        .then((datas) => {
          if (datas.data.code == 200) {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            that.$message.success("数据更新成功!");
          } else {
            that.priorvisible = false;
            that.accountappidsvisible = false;
            that.statusvisible = false;
            that.$message.error();
            ("数据更新失败!");
          }
          that.getaccounts();
        });
    },
    // 获取风格预览图
    ImgUploadSectionFile(param) {
      console.log(param);
      this.filestyle = param;
      this.styleUrl = URL.createObjectURL(param.file);
    },
    // 取消订单新增
    resetForm(form) {
      this.$refs[form].resetFields();
      this.styleloading = false;
      this.styleUrl = "";
      this.filestyle = "";
      this.accountOrder = false;
    },
    // 提交订单新增
    submitForm(form) {
      var _this = this;
      let formData = new FormData(); //formdata格式
      formData.append("Img", _this.filestyle.file);
      this.$http
        .post("https://wx.xiaoyuc.com/home/admin/adminupaccount", formData)
        .then((res) => {
          if (res.data.code == 200) {
            this.addform.imglist = res.data.imgname;
            _this.$refs[form].validate((valid) => {
              if (valid) {
                _this.$http
                  .post(
                    "https://wx.xiaoyuc.com/home/admin/addacounts",
                    _this.$qs.stringify(_this.addform)
                  )
                  .then(({ data }) => {
                    if (data.code == 200) {
                      _this.$message.success("下单成功!");
                      _this.resetForm(form);
                    } else {
                      _this.$message.error("下单失败!");
                    }

                    _this.getaccounts();
                  });
              } else {
                return false;
              }
            });
          } else {
            _this.$message.error("二维码上传失败，请重新尝试！");
          }
        });
    },
    // 新增订单
    addprogram() {
      this.accountOrder = true;
    },
    //复制链接
    onCopy(e) {
      this.$message.success("内容已复制到剪切板！");
    },
    // 获取订单详细数据
    getOrderDetails(id){
        this.orderids = id,
        this.orderShows = true
    },
    hidemys(names){
      this.orderShows = names
    }
  },
  beforeDestroy(){
      pubsub.unsubscribe(this.pubId)
  }
};
</script>

<style scoped>
.contains {
  width: 100%;
  position: relative;
}
.contains /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  display: none !important;
}
.buttons {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contains /deep/ .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  padding: 10px 25px !important;
  font-size: 14px !important;
}
.el-radio-group {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 158px;
  height: 158px;
  line-height: 158px;
  text-align: center;
  border: 1px dashed #ccc;
  margin-top: 10px;
}
.avatar {
  width: 158px;
  height: 158px;
  display: block;
  margin-top: 10px;
}
.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.header > .el-button {
  padding: 0 20px !important;
  height: 40px !important;
  margin-left: 20px !important;
}
.plocs{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  overflow: auto;
  z-index:999;
}
</style>