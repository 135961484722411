<template>
  <div class="contains" :style="{ height: heights + 'px' }">
    <div class="left">
      <div class="left_contents">
        <el-form label-width="80px" v-if="nums == 1">
          <el-form-item class="buttons" label-width="0">
            <el-button type="primary" @click="creats()">立即生成</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="right">
      <div class="checkalls" v-loading="lodings">
        <div class="seachers" v-if="webUrls">
          <div class="show">
            <span>设备编号：{{ srcs.device }}</span>
            <!-- <img :src="item.webUrl" /> -->
            <div ref="contents" class="qrcodess">
              <div class="tips">
                <p>{{ srcs.text1 }}</p>
                <p style="font-size:20px">{{ srcs.text2 }}</p>
                <p>{{ srcs.text3 }}</p>
                <p>{{ srcs.text4 }}</p>
                <p>{{ srcs.text5 }}</p>
                <p>{{ srcs.text6 }}</p>
              </div>
              <div class="qrros">
                <qrcodes :url="srcs.text2" :wid="200" :hei="200" />
              </div>
            </div>
            <el-button
              type="primary"
              icon="el-icon-document"
              size="small"
              style="margin: 20px 125px; width: 100px"
              @click="downloadCode()"
              >下载</el-button
            >
            <span>路径：{{}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qrcodes from "vue_qrcodes";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      devices1: "",
      devices2: "",
      nums: 1,
      bg: ["#77c3ef", "#fff", "#fff"],
      ables: true,
      ediors: "立即编辑",
      search: {
        ks_url: "",
        kslite_url: "",
        webUrl: "",
        devices: "",
      },
      search2: {
        ks_url: "",
        kslite_url: "",
        webUrl: "",
        devices: "",
      },
      webUrls: [""],
      heights: "",
      heights2: "",
      heights3: "",
      lodings: false,
      addsr: "",
      qrcodeVue: {
        size: 300,
        bgColor: "#000", //背景色
        fgColor: "#FFFFFF", //前景色
        logo: require("../assets/img/ks.jpg"), //logo图片
      },
      srcs: {},
      numstty: -1,
    };
  },
  components: { qrcodes },
  mounted() {
    const _this = this;
    _this.resizeHeight();
    window.onresize = function () {
      _this.resizeHeight();
    };
  },
  methods: {
    // 窗口自适应
    resizeHeight() {
      this.heights = document.body.clientHeight * 1 - 120;
      this.heights2 = document.body.clientHeight * 1 - 190;
      this.heights3 = document.body.clientHeight * 1 - 240;
    },
    gochecks(num) {
      this.nums = num;
      this.devices1 = "";
      this.devices2 = "";
      this.ables = true;
      this.ediors = "立即编辑";
      this.search.ks_url = "";
      this.search.kslite_url = "";
      this.search.webUrl = "";
      this.search.devices = "";
      this.search2.ks_url = "";
      this.search2.kslite_url = "";
      this.search2.webUrl = "";
      this.search2.devices = "";
      this.addsr = "";
      this.bg.forEach((element, index) => {
        this.bg[index] = "#fff";
      });
      this.bg[num * 1 - 1] = "#77c3ef";
    },
    checks() {
      const that = this;
      this.ables = true;

      this.ediors = "立即编辑";
      if (this.devices1) {
        that.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/getDevicexcx",
            that.$qs.stringify({
              deviceId: that.devices1,
            })
          )
          .then((datas) => {
            console.log(datas.data.code);
            if (datas.data.code == 201) {
              that.$message.warning("暂无该设备信息");
            } else {
              that.search.ks_url = datas.data.data.ksUrl;
              that.search.kslite_url = datas.data.data.ksLiteUrl;
              that.search.webUrl = datas.data.data.webUrl;
              that.search.devices = datas.data.data.device;
            }
            that.lodings = false;
          });
      } else {
        this.$message.warning("请先输入设备编号");
      }
    },
    editor() {
      const that = this;

      if (this.ediors == "立即编辑") {
        this.ables = false;
        this.ediors = "立即保存";
      } else {
        this.ables = true;
        this.ediors = "立即编辑";
        that.lodings = true;
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/editorDevicexcx",
            that.$qs.stringify(that.search)
          )
          .then((datas) => {
            this.$message.success("更新信息成功!");
            that.lodings = false;
          });
      }
    },
    // 生成web页面地址
    creats() {
      const that = this;
      this.lodings = true;
      this.$http
        .get("https://wx.xiaoyuc.com/home/admin/sendnowpop")
        .then((datas) => {
          console.log(datas);
          that.webUrls = datas.data.data;
          that.srcs = datas.data.data[0];
          that.lodings = false;
        });
    },
    // 新增小程序地址
    adds() {
      const that = this;
      if (this.search2.devices) {
        this.$http
          .post(
            "https://wx.xiaoyuc.com/home/admin/addDevicexcx",
            that.$qs.stringify(that.search2)
          )
          .then((datas) => {
            console.log(datas.data.code);
            if (datas.data.code == 201) {
              that.$message.success("更新小程序地址成功");
            } else {
              that.$message.success("新增小程序地址成功");
            }
            that.lodings = false;
            that.addsr = 1;
            that.devices1 = that.search2.devices;
            that.checks();
          });
      } else {
        this.$message.warning("请输入设备编号");
      }
    },
    downloadCode() {
      console.log('opopopop')
      if (this.numstty*1 < this.webUrls.length *1 -1) {
        this.numstty = this.numstty *1 +1;
        this.srcs = this.webUrls[this.numstty];
        setTimeout(()=>{
          this.dows(this.webUrls[this.numstty].text2);
        },500)
      }
    },
    dows(element) {
      let content = this.$refs.contents;
      let scrollHeight = content.scrollHeight;
      let scrollWidth = content.scrollWidth;
      html2canvas(content, {
        // scale: window.devicePixelRatio,
        scale: 2,
        useCORS: true, //开启跨域配置，但和allowTaint不能共存
        width: scrollWidth,
        height: scrollHeight,
        windowWidth: scrollWidth,
        windowHeight: scrollHeight,
        x: 0,
        y: window.pageYOffset,
      }).then((canvas) => {
        this.operType = "edit";
        let dataURL = canvas.toDataURL("image/jpg");
        let link = document.createElement("a");
        link.href = dataURL;
        let filename = `${element}.jpg`; //文件名称
        link.setAttribute("download", filename);
        link.style.display = "none"; //a标签隐藏
        document.body.appendChild(link);
        link.click();
        this.downloadCode();
      });
    },
  },
  watch: {
    "search2.devices"(newVal, oldVal) {
      this.addsr = "";
      this.search2.ks_url = "";
      this.search2.kslite_url = "";
    },
  },
};
</script>

<style scoped>
.contains {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}
.left {
  width: 30%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.right {
  width: 68%;
  height: 100%;
  background-color: rgb(245, 245, 245);
  margin-left: 2%;
  display: flex;
}
.left_header {
  width: 100%;
  height: 60px;
  background-color: rgb(245, 245, 245);
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  /* white-space: nowrap; */
}
.left_header::-webkit-scrollbar {
  display: none !important;
}
.left_header > span {
  display: inline-block;
  padding: 0 10px;
  height: 40px;
  margin: 10px 5px;
  line-height: 40px;
  font-size: 13px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.left_contents {
  flex: 1;
  margin-top: 20px;
}
.el-form {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
}
.buttons {
  text-align: center;
  margin-top: 50px;
}
.checkalls {
  width: 100%;
  height: 100%;
}
.titles {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  font-weight: 550;
}
.seachers {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.show {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #bcbcbb;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.show > img {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}
.show > span {
  display: inline-block;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.qrcode {
  width: 200px !important;
  height: 200px !important;
  margin-left: 10px;
  margin-top: 10px;
}
.qrcodess {
  width: 580px;
  height: 280px;
  display: flex;
  /* flex-direction: column; */
  background-color: rgb(255, 255, 255);
  padding: 20px;
}
/* .qrcodess > div {
    width: 350px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
.qrcodess > p {
  width: 350px;
  text-align: center;
  font-size: 16px;
  margin-top: 7px;
}
.tips {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.qrros {
  width: 220px;
  height: 100%;
}
.tips > p {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 20px;
  font-family: "Microsoft YaHei";
}
</style>
